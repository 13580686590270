import {
  Box, Flex, Text, Icon,
} from '@chakra-ui/react';
import { CurrentSubmission } from '@src/state/CurrentSubmission';
import { FiCheck, FiChevronRight } from 'react-icons/fi';
import { useRecoilValue } from 'recoil';
import { displayScores } from '@src/state/displayScores';

function TopLevelSection({
  code, section, index, currentSectionIndex, currentSubsectionIndex, handleSelectSection,
}) {
  const isSectionComplete = useRecoilValue(CurrentSubmission.isSectionComplete({
    code,
    sectionId: section.id,
  }));
  const sectionScore = useRecoilValue(CurrentSubmission.sectionScore({
    code,
    sectionId: section.id,
  }));
  const isSectionActive = index === currentSectionIndex;
  const showScores = useRecoilValue(displayScores);

  const baseSectionColor = `sections.${index}.500`;
  let effectiveColour;
  if (isSectionComplete) {
    effectiveColour = baseSectionColor;
  } else if (isSectionActive) {
    effectiveColour = baseSectionColor;
  } else {
    effectiveColour = 'gray.400';
  }

  let titleColour;
  if (isSectionActive) {
    titleColour = baseSectionColor;
  } else if (isSectionComplete) {
    titleColour = 'gray.600';
  } else {
    titleColour = 'gray.400';
  }

  return (
    <Box>
      <Flex
        w="100%"
        alignItems="center"
        position="relative"
        zIndex="2"
        mb={isSectionActive ? 4 : 6}
        onClick={() => handleSelectSection(index, 0)}
        cursor="pointer"
      >
        <Box
          w="60px"
          h="60px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="full"
          backgroundColor={effectiveColour}
          mr="16px"
        >
          {isSectionComplete && !isSectionActive
            ? <Icon color="white" fontSize="32" as={FiCheck} /> : (
              <Text fontSize={32} color="white">
                {index + 1}
              </Text>
            )}
        </Box>
        <Box flex="1">
          <Text
            fontSize={20}
            fontWeight="bold"
            lineHeight="1.2"
            color={titleColour}
          >
            {section.title}
          </Text>
          {isSectionComplete && sectionScore > 0 && showScores && (
            <Text fontSize={12} color="gray.600">
              Score:
              {sectionScore.toFixed(1)}
            </Text>
          )}
        </Box>
      </Flex>
      <Box />
      {isSectionActive && (
        <Box mb="8">
          {section.sections.map((subSection, subIndex) => (
            <SubSectionItem
              key={subSection.id}
              subSection={subSection}
              sectionColour={baseSectionColor}
              subIndex={subIndex}
              handleSelectSubsection={(selectedSubIndex) => handleSelectSection(
                index,
                selectedSubIndex,
              )}
              currentSubsectionIndex={currentSubsectionIndex}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

function SubSectionItem({
  subSection, subIndex, handleSelectSubsection, currentSubsectionIndex, sectionColour,
}) {
  const isSectionComplete = useRecoilValue(CurrentSubmission.isSectionComplete(subSection.id));

  // ASSUMPTION: this component is only rendered if the parent section is selected
  const isSectionActive = subIndex === currentSubsectionIndex;
  let effectiveColour;
  if (isSectionActive) {
    effectiveColour = 'gray.800';
  } else if (isSectionComplete) {
    effectiveColour = sectionColour;
  } else {
    effectiveColour = 'gray.400';
  }

  return (
    <Flex
      w="100%"
      alignItems="center"
      mt={4}
      mb={4}
      position="relative"
      zIndex="2"
      onClick={() => handleSelectSubsection(subIndex)}
      cursor="pointer"
    >
      <Box
        w="20px"
        h="20px"
        ml="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="full"
        backgroundColor={effectiveColour}
        color="white"
      >
        {isSectionComplete && !isSectionActive
          ? <Icon as={FiCheck} fontSize={10} boxSize="14px" />
          : ''}
        {isSectionActive ? <Icon as={FiChevronRight} boxSize="14px" fontSize={10} /> : ''}
      </Box>
      <Box flex="1">
        <Text
          fontSize={14}
          ml="36px"
          color={effectiveColour}
          fontWeight={isSectionActive ? 'bold' : 'normal'}
        >
          {subSection.title}
        </Text>
      </Box>
    </Flex>
  );
}

function SectionProgress({
  code, sections, currentSection, currentSubSection, onSelectSection,
}) {
  return (
    <Box position="relative" top={4} minWidth="290px" maxW="290px" mr={8}>
      {sections.map((section, index) => (
        <TopLevelSection
          code={code}
          key={section.id}
          section={section}
          index={index}
          currentSectionIndex={currentSection}
          currentSubsectionIndex={currentSubSection}
          handleSelectSection={onSelectSection}
        />
      ))}
      <Box w="4px" h="100%" backgroundColor="gray.100" position="absolute" left="28px" top={0} zIndex="1" />
    </Box>
  );
}

export default SectionProgress;
