import {
  Box, Flex, Grid, Container,
  GridItem, Heading, Select, SkeletonText,
  Spacer, Stack, Text, Link,
} from '@chakra-ui/react';
import { useDemographicsQuery } from '@src/api/demographics';
import { useAmcfProfileQuery } from '@src/api/profile';
import MainLayout from '@src/components/layouts/main';
import { useConfig } from '@src/context/ConfigContext';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLatestSubmissionCountForRolesQuery } from '@src/api/amcf-answers';
import { useIsUserInRole } from '@transport-insights/uikit';
import PeerCompetencyChart from './components/selfloading/PeerCompetencyChart';
import YourCompetencyChart from './components/selfloading/YourCompetencyChart';

function ReportPage({ code }) {
  const { isDev } = useConfig();
  const {
    data: demographicOptions,
    isLoading,
  } = useDemographicsQuery();
  const hasDeveloperRole = useIsUserInRole('developer');
  const { data: myProfile, isFetchedAfterMount: isProfileLoaded } = useAmcfProfileQuery();

  const [selectedRole, setSelectedRole] = useState('advisor');
  const [roleName, setRoleName] = useState('');
  const [userRole, setUserRole] = useState('');

  const {
    data: roleSubmissions,
    isLoading: isLoadingSubmissionCount,
  } = useLatestSubmissionCountForRolesQuery(code, selectedRole);

  useEffect(() => {
    if (demographicOptions?.roles && selectedRole) {
      setRoleName(demographicOptions.roles.find((r) => r.id === selectedRole).name);
    }
  }, [demographicOptions, selectedRole]);

  useEffect(() => {
    if (myProfile && demographicOptions) {
      setUserRole(demographicOptions.roles.find((r) => r.id === myProfile.roleId).name);
      setSelectedRole(myProfile.roleId);
    }
  }, [myProfile, isProfileLoaded, demographicOptions]);

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="container.xl">
        <Stack spacing="8">
          <Flex justifyContent="space-between">
            <Heading>
              {code.toUpperCase()}
              {' '}
              Report
            </Heading>
            <Text>
              Your role:
              {' '}
              <strong>{userRole}</strong>
            </Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            align="center"
            border="1px solid #ccc"
            borderRadius={5}
            padding={4}
          >
            <Text>Compare my data to the</Text>
            <Select
              id="roleId"
              value={selectedRole}
              display="inline-block"
              width="22rem"
              pl={2}
              pr={2}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {isLoading && <option value="">Loading...</option>}
              {demographicOptions?.roles?.map((x) => (
                <option value={x.id} key={x.id}>{x.name}</option>
              ))}
            </Select>
            <Text>role</Text>
            <Spacer />
            <SkeletonText noOfLines={1} isLoaded={!isLoadingSubmissionCount}>
              <Text>
                <strong>{roleSubmissions || 0}</strong>
                {' '}
                {roleName}
                {' '}
                submissions
              </Text>
            </SkeletonText>
          </Flex>
          {/* two charts */}
          <Grid templateColumns={['1fr', '1fr', '1fr', 'repeat(2,1fr)']} gap={8} gridAutoRows="1fr">
            <GridItem minWidth="0">
              <YourCompetencyChart
                code={code}
                selectedRole={selectedRole}
                roleName={roleName}
              />
            </GridItem>
            <GridItem minWidth="0">
              <PeerCompetencyChart
                code={code}
                selectedRole={selectedRole}
                roleName={roleName}
              />
            </GridItem>
          </Grid>

          {isDev && (
            <Box mt="10" p="2">
              <Heading size="sm">Dev-mode only</Heading>
              <Link fontSize="sm" as={RouterLink} to={`/test/submissions-for-role/${code}/${selectedRole}/`}>
                See submission details for
                {' '}
                {selectedRole}
              </Link>
            </Box>
          )}

          {isDev && hasDeveloperRole && (
            <Box mt="10" p="2">
              <Heading size="sm">Management-mode only</Heading>
              <Link fontSize="sm" as={RouterLink} to="/admin/statistics">
                See Statistics
              </Link>
            </Box>
          )}
        </Stack>
      </Container>
    </MainLayout>
  );
}

export default ReportPage;
