import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAuthenticatedAmcfApi from './useAuthenticatedAmcfApi';

/**
 * @typedef {object} AmcfProfile
 * @prop {string} name
 * @prop {string} ageRangeId
 * @prop {string} educationLevelId
 * @prop {string} roleId
 * @prop {string} regionId
 * @prop {string} employerId
 * @prop {string} organisationTypeId
 * @prop {string} organisationFieldOfWorkId
 */

export async function getMyProfile(amcfApiClient) {
  try {
    const res = await amcfApiClient.get('/my-profile');
    return res.data || {};
  } catch (error) {
    // If the profile doesn't exist we'll resolve a null value
    if (error.response?.status === 404) {
      return null;
    }
    throw error;
  }
}

/**
 * @param {
 * Omit<import('react-query').UseQueryOptions<
 * AmcfProfile, import('axios').AxiosError, AmcfProfile>, 'queryFn' | 'queryKey'>
 * } queryOptions
 * @returns
 */
export function useAmcfProfileQuery(queryOptions = undefined) {
  const api = useAuthenticatedAmcfApi();
  return useQuery({
    queryKey: ['my-profile'],
    queryFn: () => getMyProfile(api),
    ...queryOptions,
  });
}

/**
 * Updates your Competency Assessment profile
 *
 * This will optimistically update the query client data with the response
 *
 * @param {
 * Omit<import('react-query').UseMutationOptions<AmcfProfile,
 *  import('axios').AxiosError, AmcfProfile, any>, 'mutationFn' | 'mutationKey'>} mutationOptions
 * @returns
 */
export function useAmcfProfileMutation(mutationOptions = undefined) {
  const api = useAuthenticatedAmcfApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (profile) => api.put('/my-profile', profile).then(({ data }) => data),
    ...mutationOptions,

    onSuccess: (updatedProfile, ...rest) => {
      queryClient.setQueryData(['my-profile'], updatedProfile);
      return mutationOptions?.onSuccess?.(updatedProfile, ...rest);
    },
  });
}
