import useAuthenticatedAmcfApi from '@src/api/useAuthenticatedAmcfApi';
import { useQuery } from 'react-query';

/**
 * @typedef {object} AmcfSurveyQuestion
 * @prop {string} id the internal id for this question.
 * @prop {string} code alias for id.
 * @prop {string} moniker Externally known moniker (e.g. '1.1.1')
 * @prop {string} prompt Displayed question prompt text
 * @prop {number} [order] Ordering inside the section
 * (by default, ordered based on API response order)
 * @prop {string} [version] Changes when question is updated,
 *  should be a timestamp ('YYYYMMDDTHHmmssZ')
 */

/**
 * @typedef {object} AmcfSurveySection
 * @prop {string} id the internal id for this section
 * @prop {string} moniker Externally known moniker (e.g. '1.1')
 * @prop {string} title Display title for the section
 * @prop {string} description Displayed additional information
 * for the section (use Markdown for structure, e.g. lists)
 * @prop {number} [order] Ordering of sections inside the parent
 *  section (by default, ordered based on API response order)
 * @prop {AmcfSurveySection[]} [sections] Child sections, if this is a top-level section
 * @prop {AmcfSurveyQuestion[]} [questions] Question definitions, if this is the leaf section
 */

/**
 * @typedef {object} AmcfSurvey
 * @prop {string} code Unique, well-known, code for the survey
 * (e.g. 'amcf'), allowing for multiple surveys expansion
 * @prop {string} [version] Changes when survey configuration
 *  is updated, should be a timestamp ('YYYYMMDDTHHmmssZ')
 * @prop {string} title Display title for the survey (if allowing user to select)
 * @prop {AmcfSurveySection[]} sections Ordered section definitions
 */

/**
 * @typedef {object} UseAmcfSurveyProps
 * @prop {boolean} isLoading
 * @prop {AmcfSurvey} survey
 */

/**
 * @param {string} [code]
 * @returns {UseAmcfSurveyProps}
 */
export function useAmcfSurvey(surveyCode) {
  const api = useAuthenticatedAmcfApi();

  const { isLoading: isLoadingSurvey, data: survey } = useQuery(
    ['amcf-survey', surveyCode],
    async () => api.get(`surveys/${surveyCode}`).then(({ data }) => data),
  );

  return {
    isLoading: isLoadingSurvey,
    survey,
  };
}
