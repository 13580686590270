import { useQuery } from 'react-query';
import useAuthenticatedAmcfApi from '@src/api/useAuthenticatedAmcfApi';

const GetPeerCompetencySummary = (code, role) => {
  const api = useAuthenticatedAmcfApi();

  const { isLoading: isLoadingSummary, data: summary } = useQuery(
    // note this query name has to be unique
    ['peercompetency-summary', code, role],
    async () => api.get(`reporting/${code}/summary${role ? `?role=${role}` : ''}`).then(({ data }) => data),
  );

  return {
    isLoading: isLoadingSummary,
    summary,
  };
};

export default GetPeerCompetencySummary;
