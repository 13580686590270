import {
  Box, Button, Flex, Heading, Icon, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalHeader, ModalOverlay, Stack, Text, useDisclosure, Collapse,
} from '@chakra-ui/react';
import CompetencyScoreTable from '@src/components/shared/CompetencyScoreTable';
import { FiInfo } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown';
import SurveyQuestion from './SurveyQuestion';

export default function SurveySection({
  section, subsection, number, onDoesNotApplyClick, code,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: descrptionIsOpen,
    onToggle: onToggleDescription,
  } = useDisclosure({ defaultIsOpen: true });

  if (!section?.sections || subsection === null) return null;
  const subSection = section?.sections[subsection];

  return (
    <Stack spacing="2" pl="2">
      <Heading as="h2" fontSize="3xl" mb={2}>{section.title}</Heading>
      <Box
        mb={6}
        p={4}
        borderRadius="md"
        backgroundColor={`sections.${number}.50`}
        className="description-text"
        sx={{ ul: { padding: 'revert' } }}
      >
        <Collapse
          in={descrptionIsOpen}
          animateOpacity
          startingHeight={20}
        >
          <ReactMarkdown>{ section.description }</ReactMarkdown>
        </Collapse>
        <Button size="sm" onClick={onToggleDescription} mt="1rem" variant="link" colorScheme="gray">
          View
          {' '}
          {descrptionIsOpen ? 'Less' : 'More'}
        </Button>
      </Box>
      {subsection === 0 && (
      <Box border="1px solid" borderColor="gray.100" borderRadius="md" px={4} py={2} display="flex" alignItems="center">
        <Text>
          <strong>{section.title}</strong>
          {' '}
          does not apply to me
        </Text>
        <Button colorScheme="gray" ml={4} onClick={() => onDoesNotApplyClick?.()}>
          Set all questions as N/A
        </Button>
      </Box>
      )}

      <Heading as="h2" fontSize="xl" pt={4}>
        {subSection.moniker}
        {' '}
        {subSection.title}
      </Heading>
      <Flex alignItems="center" mb={4}>
        <Text fontSize="xs">Please rate your level of competence for each statement </Text>
        <IconButton
          aria-label="More Info"
          variant="ghost"
          colorScheme="brand.orange"
          icon={<Icon as={FiInfo} boxSize="6" />}
          ml={1}
          onClick={onOpen}
        />
      </Flex>
      {
        subSection.questions?.map((question, i) => (
          <SurveyQuestion key={`q-${i}`} question={question} number={number} surveyCode={code} />
        ))
      }
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" preserveScrollBarGap>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Six Level Assessment</ModalHeader>
          <ModalCloseButton />
          <ModalBody px="8" pb="8">
            <CompetencyScoreTable />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
