import { selectorFamily } from 'recoil';
import { CurrentSubmission } from './CurrentSubmission';
import { LastSubmission } from './LastCompleteSubmission';

export const submissionStatusSelector = selectorFamily({
  key: 'submission-status',
  get: (code) => ({ get }) => {
    const lastSubmission = get(LastSubmission.state(code));
    const draftSubmission = get(CurrentSubmission.draftSubmission(code));
    const hasUnsubmittedChanges = get(CurrentSubmission.hasUnsubmittedChanges(code));

    return {
      isInProgress: !!draftSubmission || hasUnsubmittedChanges,
      hasSubmitted: !!lastSubmission,
      lastSubmissionTime: lastSubmission?.at ? new Date(lastSubmission.at) : undefined,
      lastDraftTime: draftSubmission?.at ? new Date(draftSubmission.at) : undefined,
    };
  },
});
