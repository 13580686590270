import { Fade } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

/**
 * Fades in the children after a specified duration
 *
 * @typedef {object} FadeInProps
 * @prop {number} [after] - specifies the delay (ms) to fade in after
 * @prop {import('react').ReactNode} children
 *
 * @param {FadeInProps} props
 */
export default function FadeIn(/** @type {FadeInProps} */ { after = 0, children }) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => setVisible(true), after);
    return () => clearTimeout(timerId);
  }, [after]);

  return (
    <Fade in={visible}>
      {children}
    </Fade>
  );
}
