import { config } from '@src/config';
import { useAuthenticatedApi } from '@transport-insights/uikit';

/**
 * @returns {{isLoading: boolean, data: DemographicOptions}}
 */
export default () => {
  const api = useAuthenticatedApi(config.amcfApiUrl);

  return api;
};
