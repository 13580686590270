import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Input,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useAddEmployerMutation, useDemographicsQuery } from '@src/api/demographics';
import { getApiErrorMessage } from '@src/utils/getApiErrorMessage';

const schema = yup.object().shape({
  name: yup.string().required('Employer name is required'),
  organisationTypeId: yup.string().required('Organisation type is required'),
});

/**
 *
 * @typedef {object} CreateEmployerModalContentProps
 * @prop {string} employerName the initial value for the employer name
 * @prop {(employer: Employer) => void} onEmployerSaved
 * called once the employer has been successfully saved
 * @prop {() => void} onClose called to close the modal
 *
 * @param {CreateEmployerModalContentProps} param0
 * @returns
 */
function CreateEmployerModalContent({ employerName, onEmployerSaved, onClose }) {
  const { data: demographicOptions } = useDemographicsQuery();
  const {
    register,
    formState: { errors }, handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });

  const {
    mutate: saveEmployer, isLoading: isSubmitting, reset: resetMutation, error: submissionError,
  } = useAddEmployerMutation({
    onSuccess: (employer) => {
      onEmployerSaved(employer);
    },
  });

  return (
    <ModalContent>
      <ModalHeader>Add new employer</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <form onSubmit={handleSubmit(saveEmployer)}>
          <FormControl mb={6} isInvalid={errors.name}>
            <FormLabel htmlFor="name">Employer name</FormLabel>
            <Input
              autoFocus
              id="name"
              {...register('name')}
              defaultValue={employerName}
            />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>
          <FormControl mb={6} isInvalid={errors.organisationTypeId}>
            <FormLabel htmlFor="organisationTypeId">Organisation Type</FormLabel>
            <Select id="organisationTypeId" {...register('organisationTypeId')}>
              <option value="">Please select</option>
              {demographicOptions.organisationTypes?.map((x) => (
                <option value={x.id} key={x.id}>{x.name}</option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.organisationTypeId && errors.organisationTypeId.message}
            </FormErrorMessage>
          </FormControl>
        </form>

        {submissionError && (
          <Alert status="error">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Error creating organisation</AlertTitle>
              <AlertDescription display="block">
                {getApiErrorMessage(submissionError)}
              </AlertDescription>
            </Box>
            <CloseButton onClick={resetMutation} position="absolute" right="8px" top="8px" />
          </Alert>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          mr={3}
          isLoading={isSubmitting}
          onClick={handleSubmit(saveEmployer)}
        >
          Save
        </Button>
        <Button
          onClick={onClose}
          colorScheme="gray"
        >
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

export default CreateEmployerModalContent;
