import { getAllQuestions, getQuestionsGroupedBySection } from '@src/utils/surveyQuestionUtils';
import { atomFamily, selectorFamily } from 'recoil';

/** @type {import('recoil').RecoilValue<import('@src/api/survey-api').AmcfSurvey>} */
const amcfQuestionsState = atomFamily({
  key: 'amcfSurvey',
  default: null,
});

const topLevelSections = selectorFamily({
  key: 'amcfSurvey/topLevelSections',
  get: (code) => ({ get }) => get(amcfQuestionsState(code))?.sections ?? [],
});

const allQuestionsGroupedBySection = selectorFamily({
  key: 'amcfSurvey/allQuestionsBySection',
  get: (code) => ({ get }) => {
    const survey = get(amcfQuestionsState(code));
    return getQuestionsGroupedBySection(survey?.sections ?? []);
  },
});

const allQuestions = selectorFamily({
  key: 'amcfSurvey/allQuestions',
  get: (code) => ({ get }) => {
    const survey = get(amcfQuestionsState(code));
    return getAllQuestions(survey?.sections ?? []);
  },
});

/**
 * Selects all of the questions recursively within this section of the survey
 */
const questionsWithinSection = selectorFamily({
  key: 'amcfSurvey/questionsWithinSection',
  get: ({ code, sectionId }) => ({ get }) => (
    get(allQuestionsGroupedBySection(code))[sectionId] ?? []
  ),
});

export const AmcfSurveyQuestions = {
  state: amcfQuestionsState,
  topLevelSections,
  allQuestions,
  allQuestionsGroupedBySection,
  questionsWithinSection,
};
