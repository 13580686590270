import Loader from '@src/components/shared/Loader';
import { MainHeaderLayout } from '@transport-insights/uikit';

export default function PageLoader({ isLoading = false, children = null }) {
  if (!isLoading) return children;

  return (
    <>
      <MainHeaderLayout />
      <Loader text="Loading" h="85vh" />
    </>
  );
}
