import {
  Container, Heading, Stack, Table, Tbody, Td, Th, Thead, Tr, VStack,
} from '@chakra-ui/react';
import { useStatisticsQuery } from '@src/api/demographics';
import MainLayout from '@src/components/layouts/main/MainLayout';
import Loader from '@src/components/shared/Loader';

export default function StatisticPage() {
  return (
    <MainLayout>
      <Container maxW="container.lg">
        <Stack spacing="8">
          <Heading>Employers Admin</Heading>
          <StaticticsTable />
        </Stack>
      </Container>
    </MainLayout>
  );
}

function StaticticsTable() {
  const { data: statistics, isLoading } = useStatisticsQuery();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <VStack>
      <Table size="md">
        <Thead>
          <Tr>
            <Th>Category</Th>
            <Th>Count</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Total Profiles</Td>
            <Td>{statistics.profileCount}</Td>
          </Tr>
          <Tr>
            <Td>Profiles Added/Updated This Month</Td>
            <Td>{statistics.profilesAddedUpdatedLastMonth}</Td>
          </Tr>
          <Tr>
            <Td>Total Submissions</Td>
            <Td>{statistics.submittedSurveyCount}</Td>
          </Tr>
          <Tr>
            <Td>Submissions Added This Month</Td>
            <Td>{statistics.submissionsAddedLastMonth}</Td>
          </Tr>
          <Tr>
            <Td>Submissions Updated This Month</Td>
            <Td>{statistics.submissionsUpdatedLastMonth}</Td>
          </Tr>
          <Tr>
            <Td>Drafts Created This Month</Td>
            <Td>{statistics.numberOfDraftSubmissionsLastMonth}</Td>
          </Tr>
        </Tbody>
      </Table>

    </VStack>
  );
}
