import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { COMPETENCY_SCORES } from '@src/assets/competency-scores';
import AnswerButton from './AnswerButton';

/**
 * @typedef {object} SurveyAnswerProps
 * @prop {number} selected
 * @prop {(value: number) => void} onChange
 *
 * @param {SurveyAnswerProps} props
 */
export default function SurveyAnswer({ selected, onChange, number }) {
  return (
    <Flex justifyContent="space-between" mt={4} mb={4} position="relative">
      <Box
        position="absolute"
        backgroundColor="gray.100"
        h="4px"
        left="2"
        right="2"
        top="50%"
        transform="translateY(-50%)"
      />
      {COMPETENCY_SCORES.map((score, index) => (
        <Tooltip key={score.label} label={score.label} aria-label={score.label}>
          <AnswerButton
            type="button"
            variant={number}
            onClick={() => onChange(index)}
            aria-selected={index === selected}
            fontSize={index < 1 ? 'xs' : 'md'}
          >
            {index > 0 ? index : 'N/A'}
          </AnswerButton>
        </Tooltip>
      ))}
    </Flex>
  );
}
