import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PageLoader from '@src/components/layouts/shared/PageLoader';
import { errorState } from '@src/state/lastError';
import { useSetRecoilState } from 'recoil';
import { useMsal } from '@azure/msal-react';

export default function IdentityAccount() {
  const { instance } = useMsal();
  const [redirect, setRedirect] = useState({ loading: true, error: undefined });
  const history = useHistory();
  const setErrorMessage = useSetRecoilState(errorState);

  useEffect(() => {
    // Pass the special 'prompt' value to the Identity Server
    instance.loginRedirect()
      .catch((error) => setRedirect({ loading: false, error }));
  }, [instance]);

  useEffect(() => {
    if (redirect?.error) {
      setErrorMessage(redirect.error);
      history.push('/error');
    }
  }, [redirect, history, setErrorMessage]);

  return (
    <PageLoader isLoading={redirect.loading} />
  );
}
