/* eslint-disable max-len */
import {
  Box, Container, Grid, Heading,
} from '@chakra-ui/react';
import { useDemographicsQuery } from '@src/api/demographics';
import { useAmcfProfileMutation } from '@src/api/profile';
import MainLayout from '@src/components/layouts/main/MainLayout';
import Loader from '@src/components/shared/Loader';
import { useUserInfo } from '@transport-insights/uikit';
import { useHistory } from 'react-router-dom';
import PrivacySummaryBox from './components/PrivacySummaryBox';
import ProfileForm from './ProfileForm';

export default function RegisterPage() {
  const history = useHistory();

  const { isLoading: isLoadingUser, firstName, lastName } = useUserInfo();
  const { isLoading: isLoadingDemographics } = useDemographicsQuery();
  const isLoading = isLoadingUser || isLoadingDemographics;

  const {
    mutate,
    isLoading: isSavingProfile,
    isSuccess,
  } = useAmcfProfileMutation({
    onSuccess: () => {
      // There's a race condition between React Query and React Router. If we
      // navigate straight away, it happens before the updated profile
      // propagates into the useQuery hooks and so we send the user back to
      // /register again, before the Routes.jsx component rerenders.
      //
      // Introducing a timeout here ensures that this happens in the next batch
      // of updates, avoiding the issue.
      setTimeout(() => history.replace('/'), 0);
    },
  });

  return (
    <MainLayout showNav={false}>
      <Container maxWidth="container.lg" mt="8">
        <Heading size="lg" mb={8}>
          Please complete your Competency Assessment profile
        </Heading>
        <Grid templateColumns={['3fr 2fr']} gap={6}>
          {isLoading ? (
            <Loader mt="40" />
          ) : (
            <ProfileForm
              defaultValue={{
                name: `${firstName || ''} ${lastName || ''}`,
              }}
              saveButtonLabel="Save Profile"
              isSubmitting={isSavingProfile || isSuccess}
              onSubmit={(profile) => mutate(profile)}
            />
          )}
          <Box alignSelf="flex-start">
            <PrivacySummaryBox />
          </Box>
        </Grid>
      </Container>
    </MainLayout>
  );
}
