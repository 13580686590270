import { useAmcfProfileQuery } from '@src/api/profile';
import SURVEY_DEFINITIONS from '@src/assets/surveys';
import PageLoader from '@src/components/layouts/shared/PageLoader';
import ErrorPage from '@src/components/pages/ErrorPage';
import HomePage from '@src/components/pages/Home';
import NotFoundPage from '@src/components/pages/NotFoundPage';
import StatisticPage from '@src/components/pages/admin/StatisticsPage';
import UpdateEmployersPage from '@src/components/pages/admin/UpdateEmployersPage';
import PrivacyPage from '@src/components/pages/privacy';
import ProfilePage from '@src/components/pages/profile';
import RegisterPage from '@src/components/pages/profile/RegisterPage';
import ReportPage from '@src/components/pages/report';
import SurveyPage from '@src/components/pages/survey';
import { amcfProfileState } from '@src/state/amcfProfile';
import {
  ApiProvider, SilentSignIn,
  useIsUserInRole,
  useUserInfo,
} from '@transport-insights/uikit';
import { useEffect } from 'react';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import SurveyLoader from '@src/components/SurveyLoader';
import IdentityAccount from './auth/IdentityAccount';
import SignInIfNoAuth from './auth/SignInIfNoAuth';
import SignOut from './auth/SignOut';

function AuthenticatedRoutes() {
  const { isLoggedIn, isLoading } = useUserInfo();
  const hasAuthenticated = !isLoading && isLoggedIn;
  const hasDeveloperRole = useIsUserInRole('developer');
  const setProfileState = useSetRecoilState(amcfProfileState);

  const {
    data: myProfile,
    isFetched: hasLoadedProfile,
  } = useAmcfProfileQuery({
    enabled: hasAuthenticated,
    onSuccess: (profile) => {
      setProfileState(profile);
    },
  });

  const hasProfile = hasLoadedProfile && !!myProfile;

  return (
    <Switch>
      <PageLoader isLoading={isLoading}>
        {SURVEY_DEFINITIONS.map(({ code }) => (
          <SurveyLoader key={code} code={code} />
        ))}
        <ScrollToTop />
        <Route>
          {hasProfile
            ? (
              <Switch>
                <Route exact path="/identity-account" component={IdentityAccount} />
                {SURVEY_DEFINITIONS.map(({ code }) => (
                  <Route key={code} path={`/survey/${code}`}>
                    <SurveyPage code={code} />
                  </Route>
                ))}
                {SURVEY_DEFINITIONS.map(({ code }) => (
                  <Route key={code} path={`/report/${code}`}>
                    <ReportPage code={code} />
                  </Route>
                ))}
                <Route path="/profile" component={ProfilePage} />
                <Route path="/privacy" component={PrivacyPage} />
                <Route exact path="/" component={HomePage} />
                {/* If we're registered and somehow end up at this page, go home. Ideally this
              case should never be able to happen, and a better fix should be investigated. */}
                <Redirect path="/register" to="/" />

                <Route path="/admin/employers" component={UpdateEmployersPage} />
                {hasDeveloperRole && (
                <Route path="/admin/statistics" component={StatisticPage} />
                )}
                <Route component={NotFoundPage} />
              </Switch>
            ) : (
              <Switch>
                <Route path="/register" component={RegisterPage} />
                {/* only do this if we have a user, no user === logging out */}
                {isLoggedIn && <Redirect path="*" to="/register" />}
              </Switch>
            )}
        </Route>
      </PageLoader>
    </Switch>
  );
}

export default function Routes() {
  return (
    <ApiProvider>
      <Switch>
        <Route exact path="/signout" component={SignOut} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/notfound" component={NotFoundPage} />
        <Route>
          <SilentSignIn redirectUri={`${window.location.origin}/msal.html`}>
            <SignInIfNoAuth>
              <AuthenticatedRoutes />
            </SignInIfNoAuth>
          </SilentSignIn>
        </Route>
      </Switch>
    </ApiProvider>
  );
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}
