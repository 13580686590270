/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalOverlay,
  Select,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDemographicsQuery, useEmployersQuery } from '@src/api/demographics';
import Loader from '@src/components/shared/Loader';
import { useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import CreateEmployerModalContent from './components/CreateEmployerModalContent';

const schema = yup.object().shape({
  name: yup.string().trim().required('Your full name is required').matches(/\w+/, 'Your full name is required'),
  // email: yup.string().email('Not a valid email address').required('Your email is required'),
  ageRangeId: yup.string().required('Age range is required'),
  educationLevelId: yup.string().required('Academic achievement is required'),
  regionId: yup.string().required('Location is required'),
  roleId: yup.string().required('Role is required'),
  organisationFieldOfWorkId: yup.string().required('Organisation field of work is required'),
  organisationTypeId: yup.string().required('Organisation type is required'),
  employerId: yup.string().nullable().required('Employer is required'),
});

/**
 * @typedef {import('@src/api/profile').AmcfProfile} AmcfProfile
 * @typedef {object} ProfileFormProps
 * @prop {AmcfProfile} [defaultValue]
 * @prop {(profile: AmcfProfile) => void} onSubmit
 * @prop {boolean} [isSubmitting]
 * if provided, will put the "Update profile" button into a spinner state
 * @prop {string} [saveButtonLabel] if provided, overrides the update profile button label
 */

export default function ProfileForm(
  /** @type {ProfileFormProps} */ {
    defaultValue,
    onSubmit,
    isSubmitting: isSubmittingProp = false,
    saveButtonLabel = 'Update Profile',
  },
) {
  const { data: demographicOptions, isLoading: isLoadingDemographics } = useDemographicsQuery();
  const { data: employers, isLoading: isLoadingEmployers } = useEmployersQuery();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const selectedEmployerId = useWatch({
    control,
    name: 'employerId',
  });

  const employersById = useMemo(() => new Map(employers?.map((e) => [e.id, e])), [employers]);

  const [newEmployerField, setNewEmployerField] = useState('');
  const employerModalState = useDisclosure({
    onClose: () => {
      setNewEmployerField('');
    },
  });

  // We set the organisation type based on the employer but are copying this
  // value into the participant's profile when they change employer.
  const updateOrgFieldsFromEmployer = (employer) => {
    if (employer) {
      setValue('organisationTypeId', employer.organisationTypeId);
      trigger('organisationTypeId');
    }
  };

  const handleCreateNewEmployer = (value) => {
    setNewEmployerField(value);
    employerModalState.onOpen();
  };

  // if we show the form before the demographics options have loaded, the
  // uncontrolled selects won't detect their values.
  //
  if (isLoadingDemographics) {
    return <Loader h="50vh" />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mb={6} isInvalid={errors.name}>
          <FormLabel htmlFor="name">Full name</FormLabel>
          <Input id="name" {...register('name')} />
          <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
        </FormControl>

        {/*
        <FormControl mb={6} isInvalid={errors.email}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input id="email" type="email" {...register('email')} />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        */}

        <FormControl mb={6} isInvalid={errors.ageRangeId}>
          <FormLabel htmlFor="ageRangeId">Age range</FormLabel>
          <Select id="ageRangeId" {...register('ageRangeId')}>
            <option value="">Please select</option>
            {demographicOptions?.ageRanges?.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.ageRangeId && errors.ageRangeId.message}</FormErrorMessage>
        </FormControl>

        <FormControl mb={6} isInvalid={errors.educationLevelId}>
          <FormLabel htmlFor="educationLevelId">Highest level of academic achievement</FormLabel>
          <Select id="educationLevelId" {...register('educationLevelId')}>
            <option value="">Please select</option>
            {demographicOptions?.educationLevels?.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors.educationLevelId && errors.educationLevelId.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mb={6} isInvalid={errors.roleId}>
          <FormLabel htmlFor="roleId">Role</FormLabel>
          <Select id="roleId" {...register('roleId')}>
            <option value="">Please select</option>
            {demographicOptions?.roles?.filter((x) => x.id !== 'entire-sector').map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.roleId && errors.roleId.message}</FormErrorMessage>
        </FormControl>

        <FormControl mb={6} isInvalid={errors.regionId}>
          <FormLabel htmlFor="regionId">Location / Region</FormLabel>
          <Select id="regionId" {...register('regionId')}>
            <option value="">Please Select</option>
            {demographicOptions.regions?.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.regionId && errors.regionId.message}</FormErrorMessage>
        </FormControl>

        <FormControl mb={6} isInvalid={errors.employerId}>
          <FormLabel htmlFor="employer">Employer organisation</FormLabel>
          <Controller
            control={control}
            id="employerId"
            name="employerId"
            render={({ field: { value, onChange, onBlur } }) => (
              <CreatableSelect
                onChange={(x) => {
                  onChange(x?.id);
                  updateOrgFieldsFromEmployer(x);
                }}
                onBlur={onBlur}
                isClearable
                value={employersById.get(value)}
                options={employers}
                isLoading={isLoadingEmployers || employerModalState.isOpen}
                // the faux item created by the creatable has a label rather than name
                getOptionLabel={(e) => e.name ?? e.label}
                getOptionValue={(e) => e.id}
                placeholder="Start typing to find your employer..."
                components={{ IndicatorSeparator: null }}
                onCreateOption={handleCreateNewEmployer}
                createOptionPosition="first"
              />
            )}
          />
          {!selectedEmployerId && (
            <FormHelperText>
              Not listed? You can
              {' '}
              <Link onClick={() => handleCreateNewEmployer('')}>
                add an organisation
              </Link>
            </FormHelperText>
          )}
          <FormErrorMessage>{errors.employerId && errors.employerId.message}</FormErrorMessage>
        </FormControl>

        <FormControl mb={6} isInvalid={errors.organisationTypeId}>
          <FormLabel htmlFor="organisationTypeId">Type of organisation</FormLabel>
          <Select id="organisationTypeId" {...register('organisationTypeId')}>
            <option value="">Please select</option>
            {demographicOptions?.organisationTypes?.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors.organisationTypeId && errors.organisationTypeId.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mb={8} isInvalid={errors.organisationFieldOfWorkId}>
          <FormLabel htmlFor="organisationFieldOfWorkId">Organisation field of work</FormLabel>
          <Select id="organisationFieldOfWorkId" {...register('organisationFieldOfWorkId')}>
            <option value="">Please select</option>
            {demographicOptions.organisationFieldsOfWork?.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors.organisationFieldOfWorkId && errors.organisationFieldOfWorkId.message}
          </FormErrorMessage>
        </FormControl>

        <Flex>
          <Spacer />
          <Button type="submit" isLoading={isSubmitting || isSubmittingProp}>
            {saveButtonLabel}
          </Button>
        </Flex>
      </form>
      <Modal
        size="md"
        isOpen={employerModalState.isOpen}
        onClose={employerModalState.onClose}
        closeOnOverlayClick={false}
        isCentered
        preserveScrollBarGap
      >
        <ModalOverlay />
        <CreateEmployerModalContent
          onEmployerSaved={(employer) => {
            setValue('employerId', employer.id);
            updateOrgFieldsFromEmployer(employer);
            employerModalState.onClose();
          }}
          onClose={employerModalState.onClose}
          organisationTypes={demographicOptions?.organisationTypes}
          employerName={newEmployerField}
        />
      </Modal>
    </>
  );
}
