import { mapValues, sum } from 'lodash';
import { getQuestionsGroupedBySection } from './surveyQuestionUtils';

/**
 * Calculates the average score for some questions.
 *
 * @param {*} sectionQuestions
 * @param {*} answerMap
 * @returns
 */
export function calculateSectionScore(sectionQuestions, answerMap) {
  const validAnswers = sectionQuestions.map((q) => answerMap[q.id] ?? 0);
  // include N/A scores in calculation
  return validAnswers.length > 0 ? sum(validAnswers) / validAnswers.length : 0;
}

/**
 * Calculates the score for a survey submission.
 *
 * This will return a breakdown of the average score for each section of the
 * survey.
 *
 * @typedef {object} SurveyScoreResults
 * @prop {number[]} topLevelSectionScores the average score for each section. These correlate to the
 *     array of sections in the survey
 * @prop {{[sectionId: string]: number}} scoresBySectionId
 * the scores for each section and subsection
 *     in the survey.
 *
 * @param {import('@src/api/amcf-survey').AmcfSurvey} survey the survey questionnaire
 * @param {{[questionId: string]: number}} answerMap a map of the answers, keyed by question id
 * @returns {SurveyScoreResults}
 */
export function calculateSurveyScore(survey, answerMap) {
  const questionsBySection = getQuestionsGroupedBySection(survey.sections);
  const scoresBySectionId = mapValues(
    questionsBySection,
    (qs) => calculateSectionScore(qs, answerMap),
  );
  const topLevelSectionScores = survey.sections.map((s) => scoresBySectionId[s.id]);

  return {
    topLevelSectionScores,
    scoresBySectionId,
  };
}
