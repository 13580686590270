import MainLayout from '@src/components/layouts/main';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
} from '@chakra-ui/react';

export default function NotFoundPage() {
  return (
    <MainLayout>
      <Container maxW="container.lg">
        <Box pb={4}>
          <strong>Oops, that page does not exist.</strong>
        </Box>
        <Box pb={4}>
          <Button as={RouterLink} to="/">
            Back
          </Button>
        </Box>
      </Container>
    </MainLayout>
  );
}
