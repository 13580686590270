import { Badge, Text, Flex } from '@chakra-ui/react';
import { CurrentSubmission } from '@src/state/CurrentSubmission';
import { LastSubmission } from '@src/state/LastCompleteSubmission';
import { useRecoilValue } from 'recoil';

export default function SurveyStatusBadge({ code }) {
  const hasUnsavedChanges = useRecoilValue(CurrentSubmission.hasUnsavedChanges(code));
  const draftSubmission = useRecoilValue(CurrentSubmission.draftSubmission(code));
  const lastSubmission = useRecoilValue(LastSubmission.state(code));

  const unsavedChangesMarkup = () => <Text ml={4} fontSize="sm">You have unsaved changes</Text>;

  if (draftSubmission || !lastSubmission) {
    return (
      <Flex align="center">
        <Badge borderRadius="md" fontSize="md" px={3} py={1} colorScheme="gray" color="gray.500" variant="subtle">
          Draft
        </Badge>
        {hasUnsavedChanges && unsavedChangesMarkup()}
      </Flex>
    );
  }
  return (
    <Flex align="center">
      <Badge borderRadius="md" fontSize="md" px={3} py={1} colorScheme="green" variant="solid">
        Submitted
      </Badge>
      {hasUnsavedChanges && unsavedChangesMarkup()}
    </Flex>
  );
}
