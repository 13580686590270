// NOTE: If you get a "ReferenceError: process is not defined" on runtime, it means
// that the environment variable has not been defined. Webpack, through the configured
// EnvironmentPlugin, replaces these with string constants in the generated bundler.
// If environment variable is not defined, then it is not replaced, and line left as-is,
// meaning that browser complains about unknown "process" object!
//
// Make sure to update 'serverless.yml' to define the new environment variable.
// Then you will need to restart 'npm start', because environment variables are loaded
// before webpack starts, so webpack hot-reload feature is not applicable here.

const stage = process.env.REACT_APP_STAGE || 'dev';
const isNonProd = stage !== 'prod';
const isDev = stage === 'local' || stage === 'dev';
const isLocal = stage === 'local';

/** @typedef {typeof config} ConfigDefinition */

export const config = {
  // This name is used to group telemetry coming from the app
  appInsightsRole: 'amcf-web',
  amcfApiUrl: process.env.REACT_APP_AMCF_API_URL,
  appInsightsConnectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
  b2cClientId: process.env.REACT_APP_B2C_CLIENT_ID,
  b2cAuthority: process.env.REACT_APP_B2C_AUTHORITY,
  b2cKnownAuthorities: process.env.REACT_APP_B2C_KNOWN_AUTHORITIES,
  b2cRedirectPath: process.env.REACT_APP_B2C_REDIRECT_PATH,
  LEGACY_PMRT_URL: process.env.REACT_APP_LEGACY_PMRT_URL,
  PORTAL_NAV_API_URL: process.env.REACT_APP_PORTAL_NAV_API_URL,
  SETTINGS_API_URL: process.env.REACT_APP_SETTINGS_API_URL,
  isNonProd,
  isDev,
  isLocal,
  GOOGLE_ANALYTICS_MEASUREMENT_ID: isNonProd
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID_NON_PROD
    : process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID_PROD,
};

export const authConfig = {
  auth: {
    // This comes from the b2c setup in azure
    clientId: config.b2cClientId,
    authority: config.b2cAuthority,
    knownAuthorities: config.b2cKnownAuthorities.split(','),
    redirectUri: config.b2cRedirectPath,
    postLogoutRedirectUri: `${process.env.REACT_APP_PMRT_URL}/Home/LogOut`,
    navigateToLoginRequestUrl: false,
  },
};
