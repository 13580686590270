import { calculateSurveyScore } from '@src/utils/calculateSurveyScore';
import { isEmpty, isEqual, isNil } from 'lodash';
import { atomFamily, selectorFamily } from 'recoil';
import { AmcfSurveyQuestions } from './AmcfSurveyQuestions';
import { LastSubmission } from './LastCompleteSubmission';

/**
 * @typedef SurveyState
 * @prop {{[key: string]: number}} answers
 * @prop {Date | undefined} lastUpdated
 */

/** @type {import('recoil').RecoilState<SurveyState>} */
const currentSubmissionAtom = atomFamily({
  key: 'currentSubmission',
  default: {
    lastUpdated: undefined,
    answers: {},
  },
});

/** @type {import('recoil').RecoilState<import('@src/api/survey-api').AmcfSurveySubmission>} */
const draftSubmissionAtom = atomFamily({
  key: 'draftSubmission',
  default: null,
});

/** @type {(question: string) => import('recoil').RecoilState<number>} */
const answerValue = selectorFamily({
  key: 'currentSubmission/answerValue',
  get: ({ code, questionId }) => ({ get }) => get(currentSubmissionAtom(code)).answers[questionId],
  set: ({ code, questionId }) => ({ set }, value) => {
    set(currentSubmissionAtom(code), (prev) => ({
      ...prev,
      lastUpdated: Date.now(),
      answers: {
        ...prev.answers,
        [questionId]: value,
      },
    }));
  },
});

/** @type {import('recoil').RecoilState<{[key: string]: number}>} */
const allAnswers = selectorFamily({
  key: 'currentSubmission/allAnswers',
  get: (code) => ({ get }) => get(currentSubmissionAtom(code))?.answers ?? {},
  set: (code) => ({ set }, newValue) => set(currentSubmissionAtom(code), (prev) => ({
    ...prev,
    answers: { ...newValue },
  })),
});

/**
 * Has this answer changed from a previous submission?
 *
 * This will not be true if there was no previous submission, or if the
 * question has not been answered yet in the current survey.
 */
const hasAnswerChanged = selectorFamily({
  key: 'currentSubmission/hasAnswerChanged',
  get: ({ code, questionId }) => ({ get }) => {
    const hasSubmittedBefore = get(LastSubmission.hasSubmitted(code));
    const prevAnswer = get(LastSubmission.answerValue({ code, questionId }));
    const currentAnswer = get(answerValue({ code, questionId }));
    return hasSubmittedBefore && !isNil(currentAnswer) && currentAnswer !== prevAnswer;
  },
});

const hasUnsavedChanges = selectorFamily({
  key: 'currentSubmission/hasUnsavedChanges',
  get: (code) => ({ get }) => {
    const draftAnswers = get(draftSubmissionAtom(code))?.answers;
    const prevAnswers = get(LastSubmission.state(code))?.answers;
    const currentAnswers = get(allAnswers(code));
    if (draftAnswers) {
      return !isEqual(currentAnswers, draftAnswers);
    } if (prevAnswers) {
      return !isEqual(currentAnswers, prevAnswers ?? {});
    }
    return !isEmpty(currentAnswers);
  },
});

const hasUnsubmittedChanges = selectorFamily({
  key: 'currentSubmission/hasUnsubmittedChanges',
  get: (code) => ({ get }) => {
    const prevAnswers = get(LastSubmission.state(code))?.answers;
    const currentAnswers = get(allAnswers(code));
    return !isEqual(currentAnswers, prevAnswers ?? {});
  },
});

function allQuestionsHaveBeenAnswered(questions, answerMap) {
  return questions.every((q) => typeof answerMap[q.id] === 'number');
}

const isSectionComplete = selectorFamily({
  key: 'currentSubmission/isSectionComplete',
  get: ({ code, sectionId }) => ({ get }) => {
    const hasLoadedSurvey = !!get(AmcfSurveyQuestions.state(code));
    const questionsInSection = get(AmcfSurveyQuestions.questionsWithinSection({ code, sectionId }));
    const currentAnswers = get(allAnswers(code));
    return hasLoadedSurvey && allQuestionsHaveBeenAnswered(questionsInSection, currentAnswers);
  },
});

const isSectionNotApplicable = selectorFamily({
  key: 'currentSubmission/isSectionNotApplicable',
  get: ({ code, sectionId }) => ({ get }) => {
    const hasLoadedSurvey = !!get(AmcfSurveyQuestions.state(code));
    const questionsInSection = get(AmcfSurveyQuestions.questionsWithinSection({ code, sectionId }));
    const currentAnswers = get(allAnswers(code));
    return hasLoadedSurvey && questionsInSection.every((q) => currentAnswers[q.id] === 0);
  },
});

const isSurveyCompleted = selectorFamily({
  key: 'currentSubmission/isComplete',
  get: (code) => ({ get }) => {
    const hasLoadedSurvey = !!get(AmcfSurveyQuestions.state(code));
    const allQuestions = get(AmcfSurveyQuestions.allQuestions(code));
    const currentAnswers = get(allAnswers(code));
    return hasLoadedSurvey && allQuestionsHaveBeenAnswered(allQuestions, currentAnswers);
  },
});

const surveyScore = selectorFamily({
  key: 'currentSubmission/surveyScore',
  get: (code) => ({ get }) => {
    const survey = get(AmcfSurveyQuestions.state(code));
    const answers = get(allAnswers(code));
    return survey ? calculateSurveyScore(survey, answers) : undefined;
  },
});

const sectionScore = selectorFamily({
  key: 'sectionScoreSelector',
  get: ({ code, sectionId }) => ({ get }) => {
    const score = get(surveyScore(code));
    return score?.scoresBySectionId?.[sectionId];
  },
});

/**
 * Atoms and selectors for the current in-progress submission.
 */
export const CurrentSubmission = {
  // It would be nice to inline the definitions, but because there are circular
  // dependencies, Typescript won't infer the type of this export object :(
  // Slightly disappointing but this still offers a nicer API elsewhere in the
  // app's code as we don't need to use unwieldy names
  state: currentSubmissionAtom,
  draftSubmission: draftSubmissionAtom,
  answerValue,
  allAnswers,
  hasAnswerChanged,
  hasUnsavedChanges,
  hasUnsubmittedChanges,
  isSectionComplete,
  isSurveyCompleted,
  isSectionNotApplicable,
  sectionScore,
  surveyScore,
};
