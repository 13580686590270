import {
  Table, Thead, Tbody, Th, Tr, Td,
} from '@chakra-ui/react';
import { COMPETENCY_SCORES } from '@src/assets/competency-scores';

const ThStyle = {
  borderColor: 'brand.orange.400',
  borderBottomWidth: '3px',
  fontSize: 'xs',
  px: 2,
  py: 2,
  textTransform: 'none',
  letterSpacing: 'normal',
};

const TdStyle = {
  borderBottomColor: 'brand.orange.200',
  fontSize: 'xs',
  px: 2,
  py: 4,
  verticalAlign: 'top',
};

export default function CompetencyScoreTable() {
  return (
    <Table size="md">
      <Thead>
        <Tr>
          <Th sx={ThStyle} />
          <Th sx={ThStyle} w="15%" />
          <Th sx={ThStyle}>Knowledge</Th>
          <Th sx={ThStyle}>Standard of work</Th>
          <Th sx={ThStyle}>Autonomy</Th>
          <Th sx={ThStyle}>Coping with complexity</Th>
          <Th sx={ThStyle}>Perception of context</Th>
        </Tr>
      </Thead>
      <Tbody fontSize="sm">
        {COMPETENCY_SCORES.map((level, index) => (
          <Tr key={level.label}>
            <Td bgColor="brand.orange.50" sx={TdStyle}>{index}</Td>
            <Td bgColor="brand.orange.50" sx={TdStyle}>{level.label}</Td>
            <Td sx={TdStyle}>{level.knowledge}</Td>
            <Td sx={TdStyle}>{level.standardOfWork}</Td>
            <Td sx={TdStyle}>{level.autonomy}</Td>
            <Td sx={TdStyle}>{level.coping}</Td>
            <Td sx={TdStyle}>{level.context}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
