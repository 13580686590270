import { extendTheme } from '@chakra-ui/react';
import { baseTheme } from '@reg/ui-theme';

// custom colours and components
import colors from './foundations/colors';
import AnswerButton from './components/answerbutton';

export default extendTheme({
  // app specific colours
  colors,
  components: {
    // custom components
    AnswerButton,
  },
}, baseTheme);
