import {
  Heading, HStack, Stack, Text,
} from '@chakra-ui/react';
import {
  VictoryArea, VictoryChart, VictoryPolarAxis,
  VictoryLabel, VictoryScatter, VictoryTooltip, VictoryGroup,
} from 'victory';
import victoryTheme from '@src/assets/theme/victoryTheme';
import { Component } from 'react';
import { baseTheme } from '@reg/ui-theme';

// This is '#42cad34a' but pre-mixed with white. We use this solid colour for the chart
// area and the central fill hack. By not relying on alpha, it means there isn't a ring
// where the circle and chart area overlap and show up darker.
//
// This hack did mean reordering the elements within the chart though so that the axes
// are overlaid on top. The opacity of those was tweaked to accommodate this.
const FILL_COLOURS = [`${baseTheme.colors.brand.blue['100']}`, `${baseTheme.colors.brand.orange['300']}80`];
/* this is just a blue circle to fill the hole in the center */
// eslint-disable-next-line react/prefer-stateless-function
class CenterFill extends Component {
  render() {
    const { origin } = this.props;
    const circleStyle = {
      fill: FILL_COLOURS[1],
      strokeWidth: 0,
    };
    return (
      <g>
        <circle
          cx={origin.x}
          cy={origin.y}
          r={25}
          style={circleStyle}
        />
      </g>
    );
  }
}

/**
 * Double renders the label, with a stroke effect applied to the one behind.
 * This helps it stand out from the background a bit
 * @param {*} props
 * @returns
 */
function LabelWithStroke(props) {
  return (
    <>
      <VictoryLabel
        {...props}
        style={{
          // eslint-disable-next-line react/destructuring-assignment
          ...props.style,
          strokeWidth: 3,
          stroke: 'rgba(255,255,255,0.8)',
          strokeLinejoin: 'round',
        }}
      />
      <VictoryLabel {...props} />
    </>
  );
}

function RadarChart({
  title, subtitle, labels, multilineLabels, values, messageText,
}) {
  // transform data
  const data = [values[1]?.map((v, index) => ({
    x: labels[index],
    y: parseFloat(v),
  }))];
  data[1] = values[0]?.map((v, index) => ({
    x: labels[index],
    y: parseFloat(v),
  }));

  // the center of the chart
  const yOrigin = 175; // couldn't find a way to calculate this
  const xOrigin = 300;

  // kludgy solution to move the outer labels away from the chart
  // these numbers have been manually tweaked until the chart looked 'nice'
  const shiftLabelLocation = (axis, x, y) => {
    const delta = 15;

    // the numbers are not placed exactly at integer positions
    const rx = Math.round(x);
    const ry = Math.round(y);

    // both axis
    // top left
    if (rx > xOrigin && ry > yOrigin) {
      return delta;
    }
    // bottom right
    if (rx < xOrigin && ry < yOrigin) {
      return delta * -1;
    }
    if (axis === 'x') {
      // right
      if (rx > xOrigin && ry === yOrigin) {
        return delta * 2;
      }
      // left
      if (rx < xOrigin && ry === yOrigin) {
        return delta * -2.5;
      }
      // top right
      if (rx > xOrigin && ry < yOrigin) {
        return delta * 2;
      }
      // bottom left
      if (rx < xOrigin && ry > yOrigin) {
        return delta * -1;
      }
    }
    if (axis === 'y') {
      // top right
      if (rx > xOrigin && ry < yOrigin) {
        return delta * -1;
      }
      // top
      if (rx === xOrigin && ry < yOrigin) {
        return delta * -0.75;
      }
      // bottom
      if (rx === xOrigin && ry > yOrigin) {
        return delta * 0.5;
      }
    }
    return 0;
  };

  return (
    <Stack
      spacing={4}
      backgroundColor="white"
      padding={4}
      borderRadius="sm"
      boxShadow="md"
    >
      <Heading fontSize={16}>{title}</Heading>
      {subtitle && (<Text fontSize="12">{subtitle}</Text>)}
      <VictoryChart
        polar
        theme={victoryTheme}
        style={{ parent: { maxWidth: '100%' } }}
        width={600}
      >
        <CenterFill />
        {data?.map((area, i) => (
          <VictoryArea
            theme={victoryTheme}
            data={area}
            key={i}
            minDomain={{ y: 3 }}
            style={{
              data: {
                fill: FILL_COLOURS[i],
              },
            }}
          />
        ))}
        {labels?.map((d, i) => (
          <VictoryPolarAxis
            dependentAxis
            key={i}
            label={() => multilineLabels[i]}
            labelPlacement="vertical"
            theme={victoryTheme}
            style={{
              axis: {
                stroke: '#ccc',
                opacity: 1,
              },
              tickLabels: {
                fill: 'none',
              },
            }}
            axisValue={d}
            axisLabelComponent={(
              <VictoryLabel
                textAnchor="middle"
                theme={victoryTheme}
                style={{
                  fontSize: 12,
                }}
                dx={(datum) => shiftLabelLocation('x', datum.x, datum.y)}
                dy={(datum) => shiftLabelLocation('y', datum.x, datum.y)}
              />
            )}
            standalone={false}
          />
        ))}
        {/*  The color scale uses the color without opacity */}
        <VictoryGroup colorScale={[FILL_COLOURS[0], FILL_COLOURS[1]]}>
          {data?.map((points, i) => (
            <VictoryScatter
              size={5}
              key={i}
              data={points}
              labelPlacement="vertical"
              labels={({ datum }) => `${datum.x}\n${datum.y > 0 ? datum.y.toFixed(1) : 'N/A'}`}
              labelComponent={(
                <VictoryTooltip
                  constrainToVisibleArea
                  theme={victoryTheme}
                />
              )}
              theme={victoryTheme}
              standalone={false}
            />
          ))}
        </VictoryGroup>
        <VictoryPolarAxis
          dependentAxis
          axisAngle={90}
          labelPlacement="vertical"
          tickValues={[-1, 0, 1, 2, 3, 4, 5]}
          tickFormat={(t) => {
            if (t < 0) return 'N/A';
            if (t === 0) return '';
            return t;
          }}
          tickLabelComponent={
            <LabelWithStroke />
          }
          theme={victoryTheme}
          style={{
            tickLabels: { fill: 'gray', fontSize: 14, pointerEvents: 'none' },
            axis: { stroke: 'none', pointerEvents: 'none' },
            grid: { stroke: '#ccc', opacity: 1, pointerEvents: 'none' },
          }}
          standalone={false}
        />
        {messageText && (
          <VictoryLabel
            textAnchor="middle"
            style={{
              fontSize: 12,
              fill: '#5c5b59',
            }}
            verticalAnchor="middle"
            backgroundPadding={10}
            backgroundStyle={{
              fill: 'white',
              stroke: '#ccc',
              strokeWidth: 2,
              rx: 4,
              ry: 4,
            }}
            dy={-10}
            x={xOrigin}
            y={yOrigin}
            text={messageText}
            theme={victoryTheme}
          />
        )}
      </VictoryChart>
      <HStack>
        <HStack>
          <Text>Individual </Text>
          <Text as="div" bgColor={FILL_COLOURS[1]} w={20} h={5} />
        </HStack>
        <HStack>
          <Text>Summary </Text>
          <Text as="div" bgColor={FILL_COLOURS[0]} w={20} h={5} />
        </HStack>
      </HStack>
    </Stack>
  );
}

export default RadarChart;
