import { capitalize } from 'lodash';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useUserInfo } from '@transport-insights/uikit';
import { config } from '../config';

export default function GoogleAnalytics({ children }) {
  const { email, userId } = useUserInfo();
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(config.GOOGLE_ANALYTICS_MEASUREMENT_ID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const title = `AMCF ${location.pathname
      .split('/')
      .filter((c) => c)
      .map((c) => capitalize(c))
      .join(' ') || 'Home'}`;

    ReactGA.send(
      {
        hitType: 'pageview',
        userId: userId ?? 'anonymous',
        userEmailPostfix: email ? email.split('@')[1] : '',
        page_title: title,
      },
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, userId, location.pathname]);

  return children;
}
