import {
  Box, Grid, Button, Flex,
} from '@chakra-ui/react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {
  SideNavigation, MainHeader, currentDatabaseSelector, MainFooter,
} from '@transport-insights/uikit';

import { useRecoilValue } from 'recoil';
import { LastSubmission } from '@src/state/LastCompleteSubmission';
import { useConfig } from '@src/context/ConfigContext';

/*
Because there's currently only two surveys we made the decision to display them as
AMCF Survey | PCF Survey | AMCF Report | PCF Report
If more surveys are added we should consider a more dynamic approach
*/

export default function MainLayout({ children, showNav = true, ...other }) {
  const location = useLocation();
  const { SETTINGS_API_URL, LEGACY_PMRT_URL } = useConfig();
  const hasSubmittedAmcfSurvey = useRecoilValue(LastSubmission.hasSubmitted('amcf'));
  const hasSubmittedPcfSurvey = useRecoilValue(LastSubmission.hasSubmitted('pcf'));
  const db = useRecoilValue(currentDatabaseSelector);
  const dbString = db?.id ? db.id.toString() : null;

  const navItems = [
    { title: 'Home', target: '/', isActive: location.pathname === '/' },
    { title: 'AMCF Survey', target: '/survey/amcf', isActive: location.pathname === '/survey/amcf' },
    { title: 'PCF Survey', target: '/survey/pcf', isActive: location.pathname === '/survey/pcf' },
    ...(hasSubmittedAmcfSurvey ? [{
      title: 'AMCF Report',
      target: '/report/amcf',
      isActive: location.pathname === '/report/amcf',
    }] : []),
    ...(hasSubmittedPcfSurvey ? [{
      title: 'PCF Report',
      target: '/report/pcf',
      isActive: location.pathname === '/report/pcf',
    }] : []),
    { title: 'My Profile', target: '/profile', isActive: location.pathname === '/profile' },
    { title: 'Privacy', target: '/privacy', isActive: location.pathname === '/privacy' },
  ];

  return (
    <>
      <MainHeader
        pmrtLegacyUrl={LEGACY_PMRT_URL}
        settingsApiUrl={SETTINGS_API_URL}
        loginUrl={null}
      />
      <Grid templateColumns={{ base: 'minmax(0, auto) minmax(0, 1fr)', xl: 'minmax(0, 18rem) minmax(0, 1fr)' }}>
        <SideNavigation db={dbString} reactRouterApps={['amcf']} />
        <Flex
          flexDir="column"
        >
          <Flex
            as="main"
            role="main"
            flexGrow="1"
            flexDirection="column"
            p={4}
            {...other}
          >
            {showNav && (
            <Box mb={8} pb={4} borderBottom="1px solid" borderColor="gray.100">
              {navItems.map((x) => (
                <Button
                  key={`nav_${x.title}`}
                  as={RouterLink}
                  to={x.target}
                  variant="navLink"
                  isActive={x.isActive}
                  mr={2}
                >
                  {x.title}
                </Button>
              ))}
            </Box>
            )}
            { children }
          </Flex>
          <MainFooter />
        </Flex>
      </Grid>
    </>
  );
}
