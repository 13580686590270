import { Flex, Button, Icon } from '@chakra-ui/react';
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';

function SectionBottomNav({
  currentSection, currentSubSection, onClickPrev, onClickNext, maxSection, maxSubSection,
}) {
  return (
    <Flex
      justifyContent="space-between"
      mt={8}
      pt={6}
      pb={6}
      width="100%"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="gray.100"
    >
      <Button
        type="button"
        variant="link"
        onClick={onClickPrev}
        isDisabled={currentSection === 0 && currentSubSection === 0}
        title="Previous"
      >
        <Icon as={FiArrowLeft} mr={2} />
        Previous Section
      </Button>
      <Button
        type="button"
        variant="link"
        onClick={onClickNext}
        isDisabled={currentSection === maxSection && currentSubSection === maxSubSection}
        title="Next"
      >
        Next Section
        {' '}
        <Icon as={FiArrowRight} ml={2} />
      </Button>
    </Flex>
  );
}

export default SectionBottomNav;
