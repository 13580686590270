/* eslint-disable no-underscore-dangle */
import {
  Heading, Flex, Text,
} from '@chakra-ui/react';
import {
  VictoryBoxPlot,
  VictoryChart,
  VictoryAxis,
  VictoryLegend,
  VictoryTooltip,
  VictoryScatter,
  VictoryLabel,
} from 'victory';
import victoryTheme from '@src/assets/theme/victoryTheme';

/* Box and whiskers chart =^o.o^= */
function BoxWhiskersChart({
  title, subtitle, subtitleValue, labels, values, userValues, colours, messageText,
}) {
  const legendData = [];

  labels.forEach((l, index) => {
    legendData.push({
      name: l,
      symbol: {
        type: index > 0 ? 'square' : 'circle', // first item gets a circle
        fill: colours[index],
      },
    });
  });

  // this format does not calculate the summary stats
  // it just renders what it is given
  // { x: 1, min: 2, median: 5, max: 10, q1: 3, q3: 7 },
  // alt format, which will calculate the stats:
  // { x: 1, y: [ 1, 2, 3, 4, ..] }
  let data = [];
  if (values) {
    // PMRT-3772
    // if all the values are zero we want to set the data to null
    // so the box and whiskers render the correct colours
    data = values.map((v) => {
      if (v.p0 + v.p25 + v.p50 + v.p75 + v.p100 > 0) {
        return {
          x: parseInt(v.sectionId, 10),
          min: v.p0,
          median: v.p50,
          max: v.p100,
          q1: v.p25,
          q3: v.p75,
        };
      }
      return {
        x: parseInt(v.sectionId, 10),
        min: null,
        median: null,
        max: null,
        q1: null,
        q3: null,
      };
    });
  }

  const xOrigin = 365;
  const yOrigin = 365;

  return (
    <Flex
      backgroundColor="white"
      padding={4}
      borderRadius="sm"
      boxShadow="md"
      flexDirection="column"
      height="100%"
    >
      <Heading fontSize={16}>{title}</Heading>
      {subtitle && (
        <Text fontSize="12">
          {subtitle}
          {' '}
          <strong>{subtitleValue}</strong>
        </Text>
      )}
      <Flex align="center" justifyContent="center" flexGrow="1">
        <VictoryChart
          theme={victoryTheme}
          width={700}
          height={700}
          padding={{
            left: 50, right: 50, top: 50, bottom: 20,
          }}
        >
          <VictoryAxis
            dependentAxis
            minDomain={{ y: 0.0 }}
            maxDomain={{ y: 5.0 }}
            style={{
              grid: { stroke: '#ccc' },
              axis: { stroke: 'none' },
            }}
            tickFormat={(t) => `${t.toFixed(1)}`}
            crossAxis={false} /* ensures the 0 is shown */
            theme={victoryTheme}
          />
          <VictoryBoxPlot
            boxWidth={40}
            data={data}
            style={{
              min: {
                stroke: (datum) => colours[datum.index + 1],
                strokeWidth: 1,
              },
              max: {
                stroke: (datum) => colours[datum.index + 1],
                strokeWidth: 1,
              },
              q1: {
                fill: (datum) => colours[datum.index + 1],
                fillOpacity: 0.5,
                strokeWidth: 1,
                stroke: (datum) => colours[datum.index + 1],
              },
              q3: {
                fill: (datum) => colours[datum.index + 1],
                fillOpacity: 0.5,
                strokeWidth: 1,
                stroke: (datum) => colours[datum.index + 1],
              },
              median: {
                stroke: (datum) => colours[datum.index + 1],
                strokeWidth: 1,
              },
            }}
            theme={victoryTheme}
          />
          {userValues
            && (
            <VictoryScatter
              size={({ datum }) => (datum._y > 0.0 ? 8 : 6)}
              domain={{ x: [0, 8], y: [0.0, 5] }}
              data={[-1, ...userValues]} // bit of fudging here to get the dots in the right place
              labelPlacement="vertical"
              // eslint-disable-next-line no-underscore-dangle
              labels={({ datum }) => {
                if (datum._y > 0.0) {
                  return `${labels[datum._x]}\n${datum._y.toFixed(1)}`;
                }
                return 'N/A';
              }}
              labelComponent={(
                <VictoryTooltip
                  constrainToVisibleArea
                  theme={victoryTheme}
                  style={{
                    fontSize: 32, // bug
                  }}
                />
              )}
              standalone={false}
              style={{ data: { fill: ({ datum }) => (datum._y > 0.0 ? 'black' : '#ccc') } }}
            />
            )}
          {messageText && (
            <VictoryLabel
              textAnchor="middle"
              style={{
                fontSize: 32,
                fill: '#5c5b59',
              }}
              verticalAnchor="middle"
              backgroundPadding={20}
              backgroundStyle={{
                fill: 'white',
                stroke: '#ccc',
                strokeWidth: 2,
                rx: 8,
                ry: 8,
              }}
              dy={-20}
              x={xOrigin}
              y={yOrigin}
              text={messageText}
              theme={victoryTheme}
            />
          )}
        </VictoryChart>
        <VictoryLegend
          orientation="vertical"
          data={legendData}
          theme={victoryTheme}
          colorScale={colours}
          style={
              {
                labels: {
                  // separate the first item from the rest
                  lineHeight: (datum) => (datum.index > 0 ? 1 : 1.5),
                },
              }
            }
        />
      </Flex>
    </Flex>
  );
}

export default BoxWhiskersChart;
