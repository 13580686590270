import { useMutation, useQuery, useQueryClient } from 'react-query';
import { sortBy, uniqBy } from 'lodash';
import useAuthenticatedAmcfApi from './useAuthenticatedAmcfApi';

/**
 * @typedef {object} DemographicOptions
 * @prop {{ id: string, name: string, order: number }[]} [ageRanges]
 * @prop {{ id: string, name: string, order: number }[]} [educationLevels]
 * @prop {{ id: string, name: string, order: number }[]} [roles]
 * @prop {{ id: string, name: string, order: number }[]} [regions]
 * @prop {{ id: string, name: string, order: number }[]} [organisationTypes]
 * @prop {{ id: string, name: string, order: number }[]} [organisationFieldsOfWork]
 */

/**
 * @typedef {object} Employer
 * @prop {string} id server assigned uuid
 * @prop {string} name the name of the employer
 * @prop {string} organisationTypeId default type of this organisation
 * @prop {boolean} validated if true, this employer has been approved
 */

/**
 * @typedef {object} EmployerPost
 * @prop {string} name
 * @prop {string} organisationTypeId
 */

/**
 * @returns {{isLoading: boolean, data: DemographicOptions}}
 */
export const useDemographicsQuery = (queryOptions = undefined) => {
  const api = useAuthenticatedAmcfApi();
  return useQuery({
    queryKey: ['demographic-options'],
    queryFn: () => api.get('/demographic-options').then(({ data }) => data),
    ...queryOptions,
  });
};

/**
 * @returns {{isLoading: boolean, data: Employer[]}}
 */
export const useEmployersQuery = () => {
  const api = useAuthenticatedAmcfApi();

  return useQuery({
    queryKey: ['employers'],
    queryFn: () => api.get('/employers').then(({ data }) => data),
  });
};

export const useStatisticsQuery = () => {
  const api = useAuthenticatedAmcfApi();
  return useQuery({
    queryKey: ['statistics'],
    queryFn: () => api.get('/statistics').then(({ data }) => data),
  });
};

/**
 *
 * @param {import('react-query').QueryClient} queryClient
 * @param {Employer[]} updatedEmployers
 */
export const updateEmployersQueryData = (queryClient, updatedEmployers) => {
  queryClient.setQueryData(
    ['employers'],
    (/** @type {Employer[]} */ existingEmployers) => sortBy(
      uniqBy([...updatedEmployers, ...(existingEmployers || [])], (e) => e.id),
      [(e) => e.name.toLowerCase()],
    ),
  );
};

export const useAddEmployerMutation = (
  // eslint-disable-next-line max-len
  /** @type {import('react-query').MutationOptions<Employer, import('axios').AxiosError, EmployerPost>} */ mutationExtras,
) => {
  const api = useAuthenticatedAmcfApi();
  const queryClient = useQueryClient();
  return useMutation({
    ...mutationExtras,
    mutationFn: (employer) => api.post('/employers', employer).then(({ data }) => data),
    onSuccess: (employer, ...args) => {
      updateEmployersQueryData(queryClient, [employer]);
      mutationExtras?.onSuccess?.(employer, ...args);
    },
  });
};
