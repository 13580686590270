import { calculateSurveyScore } from '@src/utils/calculateSurveyScore';
import { atomFamily, selectorFamily } from 'recoil';
import { AmcfSurveyQuestions } from './AmcfSurveyQuestions';

/**
 * @template T
 * @typedef {import('recoil').RecoilState<T>} RecoilState
 */

/**
 * @typedef {import('@src/api/amcf-answers').AmcfSurveySubmission} AmcfSurveySubmission
 */

/** @type {RecoilState<AmcfSurveySubmission>} */
const lastSubmissionState = atomFamily({
  key: 'lastSubmission',
  default: null,
});

const hasSubmitted = selectorFamily({
  key: 'lastSubmission/hasSubmitted',
  get: (code) => ({ get }) => !!get(lastSubmissionState(code)),
});

const allAnswers = selectorFamily({
  key: 'lastSubmission/allAnswers',
  get: (code) => ({ get }) => get(lastSubmissionState(code))?.answers ?? {},
});

const answerValue = selectorFamily({
  key: 'lastSubmission/answerValue',
  get: ({ code, questionId }) => ({ get }) => get(allAnswers(code))[questionId],
});

const submittedAt = selectorFamily({
  key: 'lastSubmission/submittedAt',
  get: (code) => ({ get }) => {
    const at = get(lastSubmissionState(code))?.at;
    return at ? new Date(at) : null;
  },
});

const scoreSummary = selectorFamily({
  key: 'lastSubmission/scoreSummary',
  get: (code) => ({ get }) => {
    const survey = get(AmcfSurveyQuestions.state(code));
    const answers = get(allAnswers(code));
    return survey ? calculateSurveyScore(survey, answers) : undefined;
  },
});

export const LastSubmission = {
  state: lastSubmissionState,
  hasSubmitted,
  answerValue,
  allAnswers,
  submittedAt,
  scoreSummary,
};
