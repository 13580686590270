import {
  Box,
  Button, Container, createStandaloneToast,
  Flex, Heading, Modal, ModalBody, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, useDisclosure, useDimensions,
} from '@chakra-ui/react';
import theme from '@src/assets/theme';
import MainLayout from '@src/components/layouts/main';
import Loader from '@src/components/shared/Loader';
import {
  useDraftSubmissionQuery,
  useLatestSubmissionQuery,
  useSaveDraftMutation,
  useSubmitSurveyMutation,
} from '@src/api/amcf-answers';
import { useAmcfSurvey } from '@src/api/amcf-survey';
import { useConfig } from '@src/context/ConfigContext';
import { amcfProfileState } from '@src/state/amcfProfile';
import { CurrentSubmission } from '@src/state/CurrentSubmission';
import { displayScores } from '@src/state/displayScores';
import { LastSubmission } from '@src/state/LastCompleteSubmission';
import { formatDate } from '@src/utils/formatDate';
import { getApiErrorMessage } from '@src/utils/getApiErrorMessage';
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import SectionBottomNav from './components/SectionBottomNav';
import SectionProgress from './components/SectionProgress';
import SurveySection from './components/SurveySection';
import SurveyStatusBadge from './components/SurveyStatusBadge';

const toast = createStandaloneToast({ theme });

function PageHeader({ code, onSubmit }) {
  const lastSubmittedAt = useRecoilValue(LastSubmission.submittedAt(code));
  const hasUnsavedChanges = useRecoilValue(CurrentSubmission.hasUnsavedChanges(code));
  const isSurveyCompleted = useRecoilValue(CurrentSubmission.isSurveyCompleted(code));
  const hasUnsubmittedChanges = useRecoilValue(CurrentSubmission.hasUnsubmittedChanges(code));
  const readyToSubmit = isSurveyCompleted && hasUnsubmittedChanges;
  const ref = useRef();
  const dimension = useDimensions(ref, true);
  const readyToSubmitModalState = useDisclosure();

  const { mutateAsync: doSaveDraft } = useSaveDraftMutation(
    code,
    {
      onSuccess: () => {
        toast({
          title: 'Draft saved',
          description: 'Your answers will be here next time you come back, but they haven\'t been submitted yet',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Error saving draft',
          description: 'There was an error saving your draft',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const handleSaveDraft = useRecoilCallback(({ snapshot }) => async () => {
    const submission = await snapshot.getPromise(CurrentSubmission.state(code));
    const profile = await snapshot.getPromise(amcfProfileState);
    await doSaveDraft({
      ...submission,
      profileId: profile.id,
    });
  }, [code]);

  const handleSubmit = () => {
    readyToSubmitModalState.isOpen = true;
    onSubmit(readyToSubmitModalState);
  };

  return (
    <Box
      width="100%"
      pos="sticky"
      top="20"
      background="white"
      boxShadow={dimension?.borderBox.top === 80 ? '1px 7px 10px -9px rgba(0,0,0,0.15);' : 'none'}
      w="100%"
      py="4"
      zIndex={200}
      ref={ref}
    >
      <Container maxW="container.xl">
        <Flex
          alignItems="center"
          justifyContent="flex-end"
        >
          <Flex mr="2" dir="row" align="center" justify="center">
            <Heading as="h1" mr={4}>
              {code.toUpperCase()}
              {' '}
              Survey

            </Heading>
            <SurveyStatusBadge code={code} />
          </Flex>
          <Spacer />
          {lastSubmittedAt && (
          <Text fontSize="sm" color="gray.400" mr={4}>
            Last submitted:
            {' '}
            <strong>{formatDate(lastSubmittedAt)}</strong>
          </Text>
          )}
          <Button
            colorScheme="gray"
            mr={4}
            disabled={!hasUnsavedChanges}
            onClick={() => handleSaveDraft()}
          >
            Save Draft
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!readyToSubmit}
          >
            Submit
          </Button>
        </Flex>
      </Container>
    </Box>
  );
}

export default function SurveyPage({ code }) {
  const [allAnswers, setAllAnswers] = useRecoilState(CurrentSubmission.allAnswers(code));

  const history = useHistory();
  const { isDev } = useConfig();

  const [showScores, setShowScores] = useRecoilState(displayScores);

  const { isLoading: isLoadingSurvey, survey } = useAmcfSurvey(code);
  const { isLoading: isLoadingDraft } = useDraftSubmissionQuery(code);
  const { isLoading: isLoadingLastSubmission } = useLatestSubmissionQuery(code);

  const isLoading = isLoadingSurvey || isLoadingDraft || isLoadingLastSubmission;

  const [currentSection, setCurrentSection] = useState(0);
  const [currentSubSection, setCurrentSubSection] = useState(0);

  const [maxSection, setMaxSection] = useState(0);
  const [maxSubSection, setMaxSubSection] = useState(0);

  const currentSectionObj = survey?.sections?.[currentSection];
  const currentSubsectionObj = currentSectionObj?.sections?.[currentSubSection];

  const currentSectionScore = useRecoilValue(
    CurrentSubmission.sectionScore(code, currentSubsectionObj?.id),
  );

  const [readyToSubmitModalState, setReadyToSubmitModalState] = useState({ isOpen: false });

  const showingDevToolsState = useDisclosure();

  const {
    mutateAsync: doSubmitSurvey, reset, status: submissionStatus, error: submissionError,
  } = useSubmitSurveyMutation(
    code,
    {
      onMutate: () => {
        readyToSubmitModalState.onOpen();
      },

      onSuccess: () => {
        history.push(`/report/${code}`);
      },
    },
  );

  const closeReadyToSubmitModal = () => {
    readyToSubmitModalState.isOpen = false;
    readyToSubmitModalState.onClose();
    reset();
  };

  // set initial section
  useEffect(() => {
    if (!isLoading && survey) {
      setCurrentSection(0);
      setCurrentSubSection(0);

      const max = survey.sections.length - 1;
      setMaxSection(max);
      setMaxSubSection(survey.sections[max].sections.length - 1);
    }
  }, [survey, isLoading]);

  // The much simpler way of doing this would be to just useRecoilState(surveyState)
  // but then this component would re-render every time an answer was changed.
  // This would probably be fine, but I figured I'd try and find a better solution..
  const onSubmitSurvey = useRecoilCallback(({ snapshot }) => async () => {
    const submission = await snapshot.getPromise(CurrentSubmission.state(code));
    const profile = await snapshot.getPromise(amcfProfileState);
    await doSubmitSurvey({
      ...submission,
      profileId: profile.id,
    });
  }, [code]);

  const scrollToTop = () => window.scrollTo({ top: 0, left: 0 });

  const previousSection = () => {
    // go to previous subsection first
    if (currentSubSection > 0) {
      setCurrentSubSection(currentSubSection - 1);
    } else if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      setCurrentSubSection(survey.sections[currentSection - 1].sections.length - 1);
    }
    scrollToTop();
  };

  const nextSection = () => {
    // if the subsection is not at max, increase that first
    const submax = survey.sections[currentSection].sections.length - 1;
    if (currentSubSection < submax) {
      setCurrentSubSection(currentSubSection + 1);
    } else if (currentSection < survey.sections.length - 1) {
      setCurrentSection(currentSection + 1);
      setCurrentSubSection(0);
    }
    scrollToTop();
  };

  const markEntireSection = (nr, skip) => {
    // TODO - show a nice modal dialog box asking the user to confirm the action
    // TODO - use useRecoilCallback here so that we don't need to subscribe this
    //        component to allAnswers and avoid unnecessary rerenders

    // make a copy of the current answers
    const clonedAnswers = { ...allAnswers };

    // loop over all the questions in the current section
    const thisSection = survey.sections[currentSection];
    thisSection.sections.forEach((s) => {
      s.questions.forEach((q) => {
        // Note: this overrides previously set answers
        // set answer to 'N/A'
        clonedAnswers[q.code] = nr;
      });
    });
    // update answer state
    setAllAnswers(clonedAnswers);

    if (skip && currentSection < survey.sections.length - 1) {
      setCurrentSection(currentSection + 1);
      setCurrentSubSection(0);
    }
  };

  const toggleScores = () => {
    setShowScores(!showScores);
  };

  return (
    <MainLayout>
      {isLoading && (
        <Loader mt="60" />
      )}
      {!isLoading && survey && (
        <>
          <PageHeader
            code={code}
            onSubmit={
            (submitModalState) => setReadyToSubmitModalState(submitModalState)
          }
          />
          <Container maxW="container.xl">
            <Stack spacing="8">
              <Flex flexDirection="row">
                <Box position="relative">
                  <Box position="sticky" top="36" pb={4} pt={2}>
                    <SectionProgress
                      code={code}
                      sections={survey.sections}
                      currentSection={currentSection}
                      currentSubSection={currentSubSection}
                      onSelectSection={(section, subsection) => {
                        setCurrentSection(section);
                        setCurrentSubSection(subsection);
                      }}
                    />
                  </Box>
                </Box>
                <Box width="100%">
                  <SurveySection
                    section={survey.sections[currentSection]}
                    subsection={currentSubSection}
                    number={currentSection}
                    onDoesNotApplyClick={() => markEntireSection(0, true)}
                    code={code}
                  />
                  {currentSectionScore > 0 && showScores && (
                  <Flex mt={8} justifyContent="flex-end">
                    <Text fontSize={18}>
                      Average Score:
                      <strong>
                        {currentSectionScore.toFixed(1)}
                      </strong>
                    </Text>
                  </Flex>
                  )}
                  {isDev && (
                  <Box>
                    <Button size="xs" mb="2" variant="outline" colorScheme="gray" onClick={showingDevToolsState.onToggle}>
                      Toggle dev tools
                    </Button>
                    {showingDevToolsState.isOpen && (
                      <Stack spacing="4" p="4" bg="gray.50" mb="8" fontSize="xs">
                        <Flex alignItems="center">
                          <Text mr={4}>
                            Set Section
                            {currentSectionObj.id}
                            {' '}
                            to
                          </Text>
                          {[0, 1, 2, 3, 4, 5].map((v) => (
                            <Button
                              size="xs"
                              colorScheme="gray"
                              variant="outline"
                              mr="1"
                              key={`fud-${v}`}
                              onClick={() => markEntireSection(v)}
                            >
                              {v}
                            </Button>
                          ))}
                        </Flex>
                        <Flex alignItems="center">
                          <Button size="xs" mr="4" colorScheme="gray" variant="outline" onClick={() => setAllAnswers({})}>
                            Reset all answers
                          </Button>
                          Wipes current answer state,
                          but this may will get reset if your drafts/last
                          survey reload for any reason.
                        </Flex>
                        <Flex alignItems="center">
                          <Button size="xs" mr="4" colorScheme="gray" variant="outline" onClick={() => toggleScores({})}>
                            Toggle scores
                          </Button>
                          {showScores ? 'Hide' : 'Show'}
                          {' '}
                          all the scores
                        </Flex>
                      </Stack>
                    )}
                  </Box>
                  )}
                  <SectionBottomNav
                    currentSection={currentSection}
                    currentSubSection={currentSubSection}
                    onClickPrev={previousSection}
                    onClickNext={nextSection}
                    maxSection={maxSection}
                    maxSubSection={maxSubSection}
                  />
                </Box>
              </Flex>
            </Stack>
          </Container>
        </>
      )}
      <Modal
        isOpen={readyToSubmitModalState.isOpen}
        onClose={closeReadyToSubmitModal}
        isCentered
        preserveScrollBarGap
        closeOnEsc={!(submissionStatus === 'loading' || submissionStatus === 'success')}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        {submissionStatus === 'idle' && (
          <ModalContent>
            <ModalHeader>Confirm submission</ModalHeader>
            <ModalBody>
              <Text>
                If you&apos;ve finished filling out your survey,
                you can submit it and see your report.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeReadyToSubmitModal} colorScheme="gray">Cancel</Button>
              <Button onClick={onSubmitSurvey} ml="3">Submit</Button>
            </ModalFooter>
          </ModalContent>
        )}
        {(submissionStatus === 'loading' || submissionStatus === 'success') && (
          <ModalContent>
            <ModalBody py="16">
              <Loader text="Submitting survey" />
            </ModalBody>
          </ModalContent>
        )}
        {(submissionStatus === 'error') && (
          <ModalContent>
            <ModalHeader>Error submitting survey</ModalHeader>
            <ModalBody>
              <Text>
                There was an error submitting your survey.
              </Text>
              <Heading mt="2" size="sm">Details</Heading>
              <Text>
                {getApiErrorMessage(submissionError)}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeReadyToSubmitModal} colorScheme="gray">Cancel</Button>
              <Button onClick={onSubmitSurvey} ml="3">Retry</Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </MainLayout>
  );
}
