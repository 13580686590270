const SUPPORT_EMAIL = 'support@transportinsights.nz';
const SUPPORT_PHONE = '0800 667276';

// eslint-disable-next-line max-len
const AMCF_PROJECT_PAGE_URL = 'https://www.nzta.govt.nz/planning-and-investment/planning/transport-excellence-partnership/sector-competency/asset-management-competency-framework/';
const AMCF_PDF_DOC_URL = 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/REG-asset-management-framework.pdf';
const PCF_PROJECT_PAGE_URL = 'https://www.nzta.govt.nz/planning-and-investment/planning/transport-excellence-partnership/sector-excellence/procurement-competency-framework/';
const PCF_PDF_DOC_URL = 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/procurement-competency-framework.pdf';

export {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  AMCF_PROJECT_PAGE_URL,
  AMCF_PDF_DOC_URL,
  PCF_PROJECT_PAGE_URL,
  PCF_PDF_DOC_URL,
};
