import usePeerCompetencyQuartilesQuery from '@src/api/peercompetency-quartiles';
import { AmcfSurveyQuestions } from '@src/state/AmcfSurveyQuestions';
import { LastSubmission } from '@src/state/LastCompleteSubmission';
import { useRecoilValue } from 'recoil';
import BoxWhiskersChart from '../charts/BoxWhiskersChart';
import { standardColours } from './Constants';

const TITLE = 'Peer group competency results - ';

function PeerCompetencyChart({ code, selectedRole, roleName }) {
  const { isLoadingQuartiles, quartiles } = usePeerCompetencyQuartilesQuery(code, selectedRole);
  const topLevelSections = useRecoilValue(AmcfSurveyQuestions.topLevelSections(code));

  const labels = ['Your result', ...(topLevelSections ?? []).map((s) => s.title)];
  const colours = ['black', ...standardColours];

  const scoreSummary = useRecoilValue(LastSubmission.scoreSummary(code));
  const userValues = scoreSummary?.topLevelSectionScores;

  const doWeHaveData = () => (quartiles?.length > 0 && userValues?.length > 0);

  if (!isLoadingQuartiles && userValues) {
    return (
      <BoxWhiskersChart
        title={`${TITLE}${roleName}`}
        values={quartiles}
        labels={labels}
        colours={colours}
        userValues={doWeHaveData() ? userValues : []}
        messageText={!doWeHaveData() && 'No data'}
      />
    );
  }

  // placeholder component with loading message
  return (
    <BoxWhiskersChart
      title={`${TITLE}${roleName}`}
      values={[]}
      labels={labels}
      colours={colours}
      userValues={[]}
      messageText="Loading..."
    />
  );
}

export default PeerCompetencyChart;
