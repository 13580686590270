import {
  useDraftSubmissionQuery,
  useLatestSubmissionQuery,
} from '@src/api/amcf-answers';
import { useAmcfSurvey } from '@src/api/amcf-survey';
import { useAmcfProfileQuery } from '@src/api/profile';
import { AmcfSurveyQuestions } from '@src/state/AmcfSurveyQuestions';
import { CurrentSubmission } from '@src/state/CurrentSubmission';
import { LastSubmission } from '@src/state/LastCompleteSubmission';
import { amcfProfileState } from '@src/state/amcfProfile';
import {
  useUserInfo,
} from '@transport-insights/uikit';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

/*
This is really gross, we're using react query to load data then
storing it inside recoil - recoil supports async loading so using
react query at all seems overly complicated to me

Originally this logic was all in the router and for one survey type
So for now I've put it into this component which can be reused

e.g.
<SurveyLoader code="amcf" />
<SurveyLoader code="pcf" />

I'm still not happy with how this works but I'm trying to not
rewrite everything just to add in an additional survey type
*/
export default function SurveyLoader({ code }) {
  const setAllAnswers = useSetRecoilState(CurrentSubmission.allAnswers(code));
  const setDraftSubmission = useSetRecoilState(CurrentSubmission.draftSubmission(code));
  const setLatestSubmission = useSetRecoilState(LastSubmission.state(code));
  const setSurveyQuestions = useSetRecoilState(AmcfSurveyQuestions.state(code));
  const setProfileState = useSetRecoilState(amcfProfileState);
  const { isLoggedIn } = useUserInfo();
  const {
    data: myProfile,
    isFetched: hasLoadedProfile,
  } = useAmcfProfileQuery({
    enabled: isLoggedIn,
    onSuccess: (profile) => {
      setProfileState(profile);
    },
  });

  const hasProfile = hasLoadedProfile && !!myProfile;

  const {
    data: latestSubmission,
  } = useLatestSubmissionQuery(code, { enabled: hasProfile });
  const {
    data: draftSubmission,
  } = useDraftSubmissionQuery(code, { enabled: hasProfile });
  const { survey } = useAmcfSurvey(code);

  useEffect(() => {
    if (survey) {
      setSurveyQuestions(survey);
    }
  }, [survey, setSurveyQuestions]);

  useEffect(() => {
    if (latestSubmission) {
      setLatestSubmission(latestSubmission);
    }
    if (draftSubmission) {
      setDraftSubmission(draftSubmission);
    }
    setAllAnswers({
      ...latestSubmission?.answers,
      ...draftSubmission?.answers,
    });
  }, [setLatestSubmission, latestSubmission,
    setDraftSubmission, draftSubmission, setAllAnswers]);

  return null;
}
