import { AmcfSurveyQuestions } from '@src/state/AmcfSurveyQuestions';
import { LastSubmission } from '@src/state/LastCompleteSubmission';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import usePeerCompetencySummaryQuery from '@src/api/peercompetency-summary';
import RadarChart from '../charts/RadarChart';

const TITLE = 'Your Competency Summary';

// These labels are long and unwieldy and need wrapping. A simple text wrap algorithm gets the job
// done nicely. A max width of 20 got very close to manually wrapped and everything fits around the
// chart still so we'll call that a win for now, and it won't break if the survey changes.
function wrapLabel(/** @type {string} */ label, maxWidthChars = 20) {
  const parts = label.split(/\s+/);
  const lines = [];
  parts.forEach((part) => {
    const lastLine = lines[lines.length - 1] ?? '';
    const joined = `${lastLine} ${part}`.trim();
    if (lines.length === 0 || joined.length > maxWidthChars) {
      lines.push(part);
    } else {
      lines[lines.length - 1] = joined;
    }
  });
  return lines;
}

function YourCompetencyChart({ code, selectedRole }) {
  const surveyScore = useRecoilValue(LastSubmission.scoreSummary(code));
  const topLevelSections = useRecoilValue(AmcfSurveyQuestions.topLevelSections(code));
  const { summary } = usePeerCompetencySummaryQuery(code, selectedRole);
  const categories = topLevelSections?.map((s) => s.title);
  const multilineLabels = useMemo(() => categories.map((c) => wrapLabel(c)), [categories]);
  const personalValues = useMemo(() => (
    surveyScore ? surveyScore.topLevelSectionScores.map((v) => v.toFixed(1)) : null
  ), [surveyScore]);

  const peerValues = useMemo(() => (
    summary ? summary.sort(
      (a, b) => parseInt(a.sectionId, 10) - parseInt(b.sectionId, 10),
    ).map((s) => s.score.toFixed(1)) : []
  ), [summary]);

  const values = [personalValues, peerValues];

  if (personalValues) {
    return (
      <RadarChart
        title={TITLE}
        values={values}
        labels={categories}
        multilineLabels={multilineLabels}
      />
    );
  }

  // placeholder empty radarchart with a message
  return (
    <RadarChart
      title={TITLE}
      values={[]}
      labels={[]}
      multilineLabels={multilineLabels}
      messageText="You need to complete the survey first"
    />
  );
}

export default YourCompetencyChart;
