import {
  Box, Stack,
  Text,
} from '@chakra-ui/react';
import SurveyAnswer from '@src/components/pages/survey/components/SurveyAnswer';
import { CurrentSubmission } from '@src/state/CurrentSubmission';
import { useRecoilState } from 'recoil';

/**
 * @typedef {object} SurveyQuestionPrxops
 * @prop {import('@src/api/amcf-survey').AmcfSurveyQuestion} question
 */

/**
 * @param {import('react').PropsWithChildren<SurveyQuestionProps>} param0
 */
export default function SurveyQuestion({ surveyCode, question, number }) {
  const [answer, setAnswer] = useRecoilState(
    CurrentSubmission.answerValue({ code: surveyCode, questionId: question.code }),
  );

  return (
    <Stack>
      <Box mt={4}>
        <Text fontWeight="bold">
          { question.moniker }
          {' '}
          { question.prompt }
        </Text>
        <SurveyAnswer selected={answer} onChange={setAnswer} number={number} />
      </Box>
    </Stack>
  );
}
