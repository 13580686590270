import {
  Center, Stack, Spinner, Text, forwardRef,
} from '@chakra-ui/react';

const Loader = forwardRef(({ text = undefined, ...props }, ref) => (
  <Center role="status" {...props} ref={ref}>
    <Stack spacing="8" alignItems="center">
      <Spinner w="6rem" h="6rem" color="brand.orange.400" emptyColor="gray.100" />
      {text && <Text fontSize="xl">{text}</Text>}
    </Stack>
  </Center>
));

export default Loader;
