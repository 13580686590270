import { createContext, useContext } from 'react';

/** @typedef {import('@src/config').ConfigDefinition} ConfigDefinition  */

/** @type {import('react').Context<Readonly<ConfigDefinition>>} */
const ConfigContext = createContext(undefined);

export function useConfig() {
  return useContext(ConfigContext);
}

/**
 * @param {Object} props
 * @param {ConfigDefinition} props.config
 * @param {React.ReactNode} props.children
 */
export function ConfigProvider({ config, children }) {
  const frozenConfig = Object.freeze(config);

  return (
    <ConfigContext.Provider value={frozenConfig}>{ children }</ConfigContext.Provider>
  );
}
