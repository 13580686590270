const colors = {
  // note: I just picked these out of the design pic - probably not accurate
  sections: {
    0: {
      50: '#2267a51a',
      400: '#2267a5bf',
      500: '#2267a5',
    },
    1: {
      50: '#33a8861a',
      400: '#33a886bf',
      500: '#33a886',
    },
    2: {
      50: '#8a00001a',
      400: '#8a0000bf',
      500: '#8a0000',
    },
    3: {
      50: '#d700351a',
      400: '#d70035bf',
      500: '#d70035',
    },
    4: {
      50: '#6235671a',
      400: '#623567bf',
      500: '#623567',
    },
    5: {
      50: '#eb8e5a1a',
      400: '#eb8e5abf',
      500: '#eb8e5a',
    },
    6: {
      50: '#1c325d1a',
      400: '#1c325dbf',
      500: '#1c325d',
    },
    7: {
      50: '#42cad31a',
      400: '#42cad3bf',
      500: '#42cad3',
    },
  },
};

export default colors;
