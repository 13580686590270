import { groupBy, mapValues } from 'lodash';

/** @typedef {import('@src/api/amcf-survey').AmcfSurveySection} AmcfSurveySection */
/** @typedef {import('@src/api/amcf-survey').AmcfSurveyQuestion} AmcfSurveyQuestion */

/**
 * Gets a list of the [sectionId, questionObject] pairs within this section.
 *
 * This will likely return multiple results for each question, as an entry will
 * be created for each parent, grandparent (etc) section, giving a transitive
 * closure of descendant questions.
 *
 * You can use this result with groupBy to then get a nice map of section -> all
 * questions inside it.
 *
 * @param {AmcfSurveySection} section
 * @returns {[string, AmcfSurveyQuestion][]}
 */
function getAllQuestionEntries(section) {
  // this is basically just a translation of the logic in AmcfSurveySection.cs
  const questions = section.questions ?? [];
  const subsections = section.sections ?? [];
  const subsectionEntries = subsections.flatMap((s) => getAllQuestionEntries(s));
  return [
    ...questions.map((q) => [section.id, q]),
    ...subsectionEntries,

    // All of the descendant questions are also descendants of this section.
    ...subsectionEntries.map(([, q]) => [section.id, q]),
  ];
}

/**
 * Gets a map of each section and the questions that are contained within each.
 *
 * For a top level section, this would contain all of the questions in all of
 * the subsections too.
 *
 * @param {AmcfSurveySection[]} sections
 * @returns {{[sectionId: string]: AmcfSurveyQuestion[]}}
 */
export function getQuestionsGroupedBySection(sections) {
  const allSectionEntries = sections.flatMap((s) => getAllQuestionEntries(s));
  return mapValues(
    groupBy(allSectionEntries, ([sectionId]) => sectionId),
    (entries) => entries.map(([, question]) => question),
  );
}

/** @returns {AmcfSurveyQuestion[]} */
export function getQuestionsWithinSection(section) {
  return [
    ...section.questions ?? [],
    ...section.sections?.flatMap((s) => getQuestionsWithinSection(s)) ?? [],
  ];
}

/**
 * Recursively gets all of the questions within the given sections
 * @param {AmcfSurveySection[]} sections
 * @returns {AmcfSurveyQuestion[]}
 */
export function getAllQuestions(sections) {
  return sections.flatMap((section) => getQuestionsWithinSection(section));
}
