import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAuthenticatedAmcfApi from '@src/api/useAuthenticatedAmcfApi';

/** @typedef {'Draft' | 'Submitted'} AmcfSurveySubmissionStatus */

/**
 * @typedef {object} AmcfSurveySubmission
 * @prop {string} id Unique id allocated to this submission
 * @prop {AmcfSurveySubmissionStatus} status
 * @prop {string} [userId] the user ID that submitted this
 * @prop {string} {profileId} the Competency Assessment profile ID for this survey (uuid)
 * @prop {string} [at] ISO-8601 date/time when submission was made
 * @prop {string} surveyId the ID of the survey being taken (i.e. 'amcf')
 * @prop {{[questionId: string]: number}} answers Map of answers to the survey questions
 */

/**
 * @typedef {object} AmcfSurveySubmissionUpdate
 * @prop {string} [profileId] the Competency Assessment profile ID for this survey (uuid)
 * @prop {{[questionId: string]: number}} answers Map of answers to the survey questions
 */

/**
 * @typedef {object} UseLatestSubmissionQueryProps
 * @prop {boolean} isLoading
 * @prop {AmcfSurvey} data
 */

/**
 * @param {Object} queryOptions
 * @param {string} code
 * @returns {UseLatestSubmissionQueryProps}
 */
export function useLatestSubmissionQuery(
  code,
  queryOptions,
) {
  const api = useAuthenticatedAmcfApi();

  return useQuery({
    ...queryOptions,
    queryKey: ['amcf-latest-submission', code],
    queryFn: async () => api.get(`surveys/${code}/latest-submission`).then(({ data }) => data),
  });
}

export function useLatestSubmissionCountForRolesQuery(code, role) {
  const api = useAuthenticatedAmcfApi();

  return useQuery({
    queryKey: ['amcf-latest-submission-count-for-roles', code, role],
    queryFn: async () => api.get(`surveys/${code}/latest-submission-count-for-role${role ? `?role=${role}` : ''}`).then(({ data }) => data),
  });
}

export function useDraftSubmissionQuery(code, queryOptions) {
  const api = useAuthenticatedAmcfApi();

  return useQuery({
    ...queryOptions,
    queryKey: ['amcf-draft', code],
    queryFn: async () => api.get(`surveys/${code}/draft`).then(({ data }) => data),
  });
}

export function useSaveDraftMutation(code, mutationOptions) {
  const api = useAuthenticatedAmcfApi();

  const queryClient = useQueryClient();
  return useMutation({
    ...mutationOptions,
    mutationFn: (submission) => api.put(`surveys/${code}/draft`, submission).then(({ data }) => data),
    onSuccess: (submission, ...rest) => {
      queryClient.setQueryData(['amcf-draft', code], submission);
      return mutationOptions?.onSuccess?.(submission, ...rest);
    },
  });
}

export function useSubmitSurveyMutation(
  code,
  mutationOptions,
) {
  const api = useAuthenticatedAmcfApi();
  const queryClient = useQueryClient();

  return useMutation({
    ...mutationOptions,
    mutationFn: (submission) => api.post(`surveys/${code}/submissions`, submission).then(({ data }) => data),
    onSuccess: (submission, ...rest) => {
      queryClient.setQueryData(['amcf-latest-submission', code], submission);
      queryClient.setQueryData(['amcf-draft', code], null);
      // Invalidate queries to ensure we have fresh data for the report
      queryClient.invalidateQueries(['amcf-latest-submission-count-for-roles']);
      queryClient.invalidateQueries(['amcf-survey']);
      queryClient.invalidateQueries(['peercompetency-summary']);
      queryClient.invalidateQueries(['peercompetency-quartiles']);
      return mutationOptions?.onSuccess?.(submission, ...rest);
    },
  });
}
