import { keyBy } from 'lodash';

/**
 * Reads data from an Excel (or CSV or whatever) spreadsheet and produces the
 * data needed to send to backend to upsert employers.
 *
 * Currently this just supports a very rudimentary format with name and org type
 *
 * @param {*} arrayBuffer
 * @returns
 */
export async function readEmployersFromExcel(arrayBuffer, demographics) {
  const [XLSX, demographicOptions] = await Promise.all([import('xlsx'), demographics]);

  // TODO: a much better format here would have id, Name, organisation type and validated so that we
  // could download and reupload.
  const workbook = XLSX.read(arrayBuffer);
  const result = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
    header: ['name', 'organisationType'],
  });
  const orgTypesByName = keyBy(demographicOptions.organisationTypes, (ot) => ot.name);
  const orgTypesById = keyBy(demographicOptions.organisationTypes, (ot) => ot.id);

  return result.map(({ name, organisationType }) => ({
    name,
    organisationTypeId: orgTypesByName[organisationType]?.id ?? orgTypesById[organisationType]?.id,
  }));
}
