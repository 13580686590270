import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PageLoader from '@src/components/layouts/shared/PageLoader';
import { errorState } from '@src/state/lastError';
import { useSetRecoilState } from 'recoil';
import { useMsal } from '@azure/msal-react';
import { useUserInfo } from '@transport-insights/uikit';
import { useConfig } from '@src/context/ConfigContext';

export default function SignInIfNoAuth({ children }) {
  const { instance } = useMsal();
  const { isLoggedIn } = useUserInfo();
  const history = useHistory();
  const [check, setCheck] = useState({ loading: true, error: undefined });
  const setErrorMessage = useSetRecoilState(errorState);
  const { isLocal } = useConfig();

  useEffect(() => {
    if (!isLoggedIn) {
      if (isLocal) {
        instance.loginRedirect()
          .catch((error) => setCheck({ loading: false, error }));
      } else {
        window.location.href = '/Account/Login';
      }
    } else {
      setCheck({ loading: false });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, instance]);

  useEffect(() => {
    if (check?.error) {
      setErrorMessage(check.error);
      history.push('/error');
    }
  }, [check, history, setErrorMessage]);

  return (<PageLoader loading={check.loading}>{children}</PageLoader>);
}
