import useAuthenticatedAmcfApi from '@src/api/useAuthenticatedAmcfApi';
import { useQuery } from 'react-query';

const GetPeerCompetencyQuartiles = (code, role) => {
  const api = useAuthenticatedAmcfApi();

  const { isLoading: isLoadingReport, data: quartiles } = useQuery(
    // note this query name has to be unique
    ['peercompetency-quartiles', code, role],
    async () => api.get(`reporting/${code}/quartiles${role ? `?role=${role}` : ''}`).then(({ data }) => data),
  );

  return {
    isLoading: isLoadingReport,
    quartiles,
  };
};

export default GetPeerCompetencyQuartiles;
