/* eslint-disable max-len */
import { Box, Link, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export default function PrivacySummaryBox() {
  return (
    <Box
      background="gray.50"
      p={4}
      borderRadius="md"
      alignSelf="flex-start"
    >
      <Text fontWeight="bold">Why are we collecting this information?</Text>
      <Text mb={4}>
        Phase 1 of the Road Efficiency Group Te Ringa Maimoa survey and reporting portal is intended to achieve
        a baseline of competency information. Once this is complete Road Efficiency Group Te Ringa Maimoa will be
        able to undertake analytics to better understand sector capability and
        capacity.
      </Text>
      <Text mb={4}>
        Findings of this process will be incorporated into Phase 2 of the
        project, which will likely include updates to the typical asset
        management role types, and communication with employer organisations
        regarding training options.
      </Text>
      <Text>
        For further information please refer to our
        {' '}
        <Link as={RouterLink} to="/privacy">
          privacy policy
        </Link>
      </Text>
    </Box>
  );
}
