const standardColours = [
  '#2267a5',
  '#33a886',
  '#8a0000',
  '#d70035',
  '#623567',
  '#eb8e5a',
  '#1c325d',
  '#42cad3',
];

const pieChartColours = [
  '#2267a5',
  '#33a886',
  '#8a0000',
  '#d70035',
  '#623567',
  '#eb8e5a',
  '#1c325d',
  '#42cad3',
];

export {
  standardColours,
  pieChartColours,
};
