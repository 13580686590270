import { forwardRef } from 'react';
import {
  Button,
  useStyleConfig,
} from '@chakra-ui/react';

// eslint-disable-next-line react/display-name
const AnswerButton = forwardRef((props, ref) => {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig('AnswerButton', { variant });
  // Pass the computed styles into the `__css` prop
  return (
    <Button
      ref={ref}
      __css={styles}
      {...rest}
    >
      {children}
    </Button>
  );
});

export default AnswerButton;
