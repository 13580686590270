export const COMPETENCY_SCORES = [
  {
    label: 'Not relevant to this role',
  },
  {
    label: 'Novice',
    knowledge: 'Minimal or \'textbook\' knowledge without connecting it to practice',
    standardOfWork: 'Unlikely to be satisfactory unless closely supervised',
    autonomy: 'Needs close supervision or instruction',
    coping: 'Little or no conception of dealing with complexity',
    context: 'Tends to see actions in isolation',
  },
  {
    label: 'Beginner',
    knowledge: 'Working knowledge of key aspects of practice',
    standardOfWork: 'Likely to complete straight forward tasks to an acceptable standard',
    autonomy: 'Able to achieve some steps using own judgement, but needs supervision for overall task',
    coping: 'Appreciates complex situations but only able to achieve partial resolution',
    context: 'Sees actions as a series of steps',
  },
  {
    label: 'Competent',
    knowledge: 'Good working and background knowledge of area of practice',
    standardOfWork: 'Fit for purpose though may lack refinement',
    autonomy: 'Able to achieve most tasks using own judgement',
    coping: 'Copes with complex situations through deliberate analysis and planning',
    context: 'Sees actions at least partly in terms of longer-term goals',
  },
  {
    label: 'Proficient',
    knowledge: 'Depth of understanding of discipline and area of practice',
    standardOfWork: 'Routinely achieves fully acceptable standard',
    autonomy: 'Able to take full responsibility for own work (and that of others where applicable)',
    coping: 'Deals with complex situations holistically; decision-making more confident',
    context: 'Sees overall \'picture\' and how individual actions fit within it',
  },
  {
    label: 'Expert',
    knowledge: 'Authoritative knowledge of discipline and deep tacit understanding across area of practice',
    standardOfWork: 'Achieves excellence with relative ease',
    autonomy: 'Able to take responsibility for going beyond existing standards and creating own interpretations',
    coping: 'Holistic grasp of complex situations; moves between intuitive and analytical approaches with ease',
    context: 'Sees overall \'picture\' and alternative approaches; vision of what may be possible',
  },
];
