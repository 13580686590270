import {
  Box, Container, Grid, Heading,
} from '@chakra-ui/react';
import { useDemographicsQuery } from '@src/api/demographics';
import { useAmcfProfileMutation, useAmcfProfileQuery } from '@src/api/profile';
import MainLayout from '@src/components/layouts/main';
import FadeIn from '@src/components/shared/FadeIn';
import Loader from '@src/components/shared/Loader';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PrivacySummaryBox from './components/PrivacySummaryBox';
import ProfileForm from './ProfileForm';

export default function ProfilePage() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { data: myProfile, isFetchedAfterMount: isProfileLoaded } = useAmcfProfileQuery({
    // We always want a fresh copy of the profile -- staleTime 0 means that any
    // existing data will immediately be considered stale, and using the
    // isFetchedAfterMount flag to hide the form will ensure we
    // always have the freshest data
    staleTime: 0,
    refetchOnMount: true,
  });

  const { isLoading: isLoadingDemographics } = useDemographicsQuery();
  const { mutate, isLoading: isSavingProfile } = useAmcfProfileMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('amcf-latest-submission-count-for-roles');
      queryClient.invalidateQueries(['peercompetency-summary']);
      queryClient.invalidateQueries(['peercompetency-quartiles']);
      history.replace('/');
    },
  });

  return (
    <MainLayout>
      <Container maxWidth="container.lg">
        <Heading mb={8}>My Profile</Heading>
        <Grid templateColumns={['3fr 2fr']} gap={6}>
          <Box>
            {!isProfileLoaded || isLoadingDemographics ? (
              <Loader mt="40" />
            ) : (
              <FadeIn>
                <ProfileForm
                  defaultValue={myProfile}
                  saveButtonLabel="Update Profile"
                  isSubmitting={isSavingProfile}
                  onSubmit={(profile) => mutate(profile)}
                />
              </FadeIn>
            )}
          </Box>
          <Box alignSelf="flex-start">
            <PrivacySummaryBox />
          </Box>
        </Grid>
      </Container>
    </MainLayout>
  );
}
