const AnswerButton = {
  baseStyle: {
    textDecoration: 'none',
    lineHeight: 1,
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    border: '5px solid',
    borderColor: 'gray.100',
    fontSize: '16px',
    fontWeight: 'bold',
    background: 'white',
    userSelect: 'none',
    _hover: {
      color: 'white',
    },
    _selected: {
      color: 'white',
    },
    zIndex: 2,
  },
  variants: {
    0: {
      _hover: {
        background: 'sections.0.500',
        borderColor: 'sections.0.500',
      },
      _selected: {
        borderColor: 'sections.0.500',
        background: 'sections.0.500',
        _hover: {
          background: 'sections.0.400',
        },
      },
    },
    1: {
      _hover: {
        background: 'sections.1.500',
        borderColor: 'sections.1.500',
      },
      _selected: {
        borderColor: 'sections.1.500',
        background: 'sections.1.500',
        _hover: {
          background: 'sections.1.400',
        },
      },
    },
    2: {
      _hover: {
        background: 'sections.2.500',
        borderColor: 'sections.2.500',
      },
      _selected: {
        borderColor: 'sections.2.500',
        background: 'sections.2.500',
        _hover: {
          background: 'sections.2.400',
        },
      },
    },
    3: {
      _hover: {
        background: 'sections.3.500',
        borderColor: 'sections.3.500',
      },
      _selected: {
        borderColor: 'sections.3.500',
        background: 'sections.3.500',
        _hover: {
          background: 'sections.3.400',
        },
      },
    },
    4: {
      _hover: {
        background: 'sections.4.500',
        borderColor: 'sections.4.500',
      },
      _selected: {
        borderColor: 'sections.4.500',
        background: 'sections.4.500',
        _hover: {
          background: 'sections.4.400',
        },
      },
    },
    5: {
      _hover: {
        background: 'sections.5.500',
        borderColor: 'sections.5.500',
      },
      _selected: {
        borderColor: 'sections.5.500',
        background: 'sections.5.500',
        _hover: {
          background: 'sections.5.400',
        },
      },
    },
    6: {
      _hover: {
        background: 'sections.6.500',
        borderColor: 'sections.6.500',
      },
      _selected: {
        borderColor: 'sections.6.500',
        background: 'sections.6.500',
        _hover: {
          background: 'sections.6.400',
        },
      },
    },
    7: {
      _hover: {
        background: 'sections.7.500',
        borderColor: 'sections.7.500',
      },
      _selected: {
        borderColor: 'sections.7.500',
        background: 'sections.7.500',
        _hover: {
          background: 'sections.7.400',
        },
      },
    },
  },
  sizes: {
    lg: {
      fontSize: 'md',
      borderRadius: 'md',
    },
    md: {
      fontSize: 'md',
    },
    sm: {
      fontSize: 'xs',
    },
    xs: {
      fontSize: 'xs',
    },
  },
  defaultProps: {
    size: 'md',
    variant: '0',
  },
};

export default AnswerButton;
