import {
  Box, Button, Container, Heading, Stack, Table, Tbody, Td, Th, Thead, Tr, Text,
} from '@chakra-ui/react';
import { updateEmployersQueryData, useDemographicsQuery, useEmployersQuery } from '@src/api/demographics';
import useAuthenticatedAmcfApi from '@src/api/useAuthenticatedAmcfApi';
import MainLayout from '@src/components/layouts/main/MainLayout';
import Loader from '@src/components/shared/Loader';
import { readEmployersFromExcel } from '@src/utils/readEmployersFromExcel';
import { useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export default function UpdateEmployersPage() {
  return (
    <MainLayout>
      <Container maxW="container.lg">
        <Stack spacing="8">
          <Heading>Employers Admin</Heading>
          <EmployersTable />

          <Box>
            <Heading size="md" mb="4">Upload</Heading>
            <Text my="4">
              You can update employers by uploading a spreadsheet (xlsx file or CSV). This
              should contain two columns; the employer name and the organisation type.
              Don&apos;t include
              a header row.
            </Text>
            <EmployerSpreadsheetUploader />
          </Box>

        </Stack>
      </Container>
    </MainLayout>
  );
}

function EmployersTable() {
  const { data: employers, isLoading } = useEmployersQuery();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Organisation Type</Th>
          <Th>Is Validated</Th>
        </Tr>
      </Thead>
      <Tbody>
        {employers.map((r) => (
          <Tr key={r.id}>
            <Td>{r.name}</Td>
            <Td>{r.organisationTypeId}</Td>
            <Td>{String(r.validated)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function EmployerSpreadsheetUploader() {
  const filePickerRef = useRef();
  const amcfApiClient = useAuthenticatedAmcfApi();
  const { data: demographics } = useDemographicsQuery();
  const queryClient = useQueryClient();
  const { mutate, isLoading, reset } = useMutation({
    mutationFn: async (employersToUpsert) => (await amcfApiClient.post('/update-employers', employersToUpsert)).data,
    onSuccess: (updatedEmployers) => {
      updateEmployersQueryData(queryClient, updatedEmployers);
      reset();
    },
  });

  const onFileSelected = async (e) => {
    try {
      const file = await e.target.files[0].arrayBuffer();
      const results = await readEmployersFromExcel(file, demographics);
      mutate(results);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(`Oh no: ${error}`);
    }
  };

  return (
    <>
      <input value="" ref={filePickerRef} type="file" style={{ display: 'none' }} onChange={onFileSelected} />
      <Button onClick={() => filePickerRef.current?.click()} isLoading={isLoading}>
        Upload
      </Button>
    </>
  );
}
