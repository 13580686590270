const SURVEY_DEFINITIONS = [
  {
    code: 'amcf',
    name: 'Asset Management Competency Framework',
  },
  {
    code: 'pcf',
    name: 'Procurement Competency Framework',
  },
];

export default SURVEY_DEFINITIONS;
